/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';

/**
 * 
 * @export
 * @interface OtherCodelistItem
 */
export interface OtherCodelistItem {
    /**
     * 
     * @type {CodelistItem}
     * @memberof OtherCodelistItem
     */
    affectedType?: CodelistItem | null;
    /**
     * 
     * @type {number}
     * @memberof OtherCodelistItem
     */
    altitude?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherCodelistItem
     */
    area?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OtherCodelistItem
     */
    fictionPlace: boolean | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof OtherCodelistItem
     */
    chvoType?: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof OtherCodelistItem
     */
    internationalPlaceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherCodelistItem
     */
    replacePlace?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OtherCodelistItem
     */
    shs: boolean | null;
}

/**
 * Check if a given object implements the OtherCodelistItem interface.
 */
export function instanceOfOtherCodelistItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fictionPlace" in value;
    isInstance = isInstance && "shs" in value;

    return isInstance;
}

export function OtherCodelistItemFromJSON(json: any): OtherCodelistItem {
    return OtherCodelistItemFromJSONTyped(json, false);
}

export function OtherCodelistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherCodelistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affectedType': !exists(json, 'affectedType') ? undefined : CodelistItemFromJSON(json['affectedType']),
        'altitude': !exists(json, 'altitude') ? undefined : json['altitude'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'fictionPlace': json['fictionPlace'],
        'chvoType': !exists(json, 'chvoType') ? undefined : CodelistItemFromJSON(json['chvoType']),
        'internationalPlaceCode': !exists(json, 'internationalPlaceCode') ? undefined : json['internationalPlaceCode'],
        'replacePlace': !exists(json, 'replacePlace') ? undefined : json['replacePlace'],
        'shs': json['shs'],
    };
}

export function OtherCodelistItemToJSON(value?: OtherCodelistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affectedType': CodelistItemToJSON(value.affectedType),
        'altitude': value.altitude,
        'area': value.area,
        'fictionPlace': value.fictionPlace,
        'chvoType': CodelistItemToJSON(value.chvoType),
        'internationalPlaceCode': value.internationalPlaceCode,
        'replacePlace': value.replacePlace,
        'shs': value.shs,
    };
}

