/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodelistCriteria
 */
export interface CodelistCriteria {
    /**
     * 
     * @type {string}
     * @memberof CodelistCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof CodelistCriteria
     */
    code?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistCriteria
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CodelistCriteria
     */
    acronym?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistCriteria
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistCriteria
     */
    manager?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistCriteria
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistCriteria
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistCriteria
     */
    effectiveTo?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CodelistCriteria
     */
    codes?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CodelistCriteria
     */
    searchFields?: Array<CodelistCriteriaSearchFieldsEnum>;
}


/**
 * @export
 */
export const CodelistCriteriaSearchFieldsEnum = {
    Code: 'code',
    Acronym: 'acronym',
    Name: 'name',
    Manager: 'manager'
} as const;
export type CodelistCriteriaSearchFieldsEnum = typeof CodelistCriteriaSearchFieldsEnum[keyof typeof CodelistCriteriaSearchFieldsEnum];


/**
 * Check if a given object implements the CodelistCriteria interface.
 */
export function instanceOfCodelistCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CodelistCriteriaFromJSON(json: any): CodelistCriteria {
    return CodelistCriteriaFromJSONTyped(json, false);
}

export function CodelistCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'acronym': !exists(json, 'acronym') ? undefined : json['acronym'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'manager': !exists(json, 'manager') ? undefined : json['manager'],
        'validFrom': !exists(json, 'validFrom') ? undefined : json['validFrom'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'codes': !exists(json, 'codes') ? undefined : json['codes'],
        'searchFields': !exists(json, 'searchFields') ? undefined : json['searchFields'],
    };
}

export function CodelistCriteriaToJSON(value?: CodelistCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'code': value.code,
        'deleted': value.deleted,
        'acronym': value.acronym,
        'name': value.name,
        'manager': value.manager,
        'validFrom': value.validFrom,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'codes': value.codes,
        'searchFields': value.searchFields,
    };
}

