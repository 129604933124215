/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringExistInYear
 */
export interface MonitoringExistInYear {
    /**
     * 
     * @type {number}
     * @memberof MonitoringExistInYear
     */
    monitoringType?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringExistInYear
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringExistInYear
     */
    historyGroupId?: number;
}

/**
 * Check if a given object implements the MonitoringExistInYear interface.
 */
export function instanceOfMonitoringExistInYear(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringExistInYearFromJSON(json: any): MonitoringExistInYear {
    return MonitoringExistInYearFromJSONTyped(json, false);
}

export function MonitoringExistInYearFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringExistInYear {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monitoringType': !exists(json, 'monitoringType') ? undefined : json['monitoringType'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'historyGroupId': !exists(json, 'historyGroupId') ? undefined : json['historyGroupId'],
    };
}

export function MonitoringExistInYearToJSON(value?: MonitoringExistInYear | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monitoringType': value.monitoringType,
        'year': value.year,
        'historyGroupId': value.historyGroupId,
    };
}

