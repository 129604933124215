/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Attachment,
  EndpointExceptionBody,
} from '../models/index';
import {
    AttachmentFromJSON,
    AttachmentToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
} from '../models/index';

export interface AttachmentApiDeleteAttachmentRequest {
    code: number;
}

export interface AttachmentApiGetFileRequest {
    code: number;
}

export interface AttachmentApiUploadRequest {
    code: number;
    name: string;
    multipartFile: Blob;
}

/**
 * 
 */
export class AttachmentApi extends runtime.BaseAPI {

    /**
     */
    async deleteAttachmentRaw(requestParameters: AttachmentApiDeleteAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling deleteAttachment.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteAttachment(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteAttachmentRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFileRaw(requestParameters: AttachmentApiGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getFile(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getFileRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadRaw(requestParameters: AttachmentApiUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling upload.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling upload.');
        }

        if (requestParameters.multipartFile === null || requestParameters.multipartFile === undefined) {
            throw new runtime.RequiredError('multipartFile','Required parameter requestParameters.multipartFile was null or undefined when calling upload.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.multipartFile !== undefined) {
            formParams.append('multipartFile', requestParameters.multipartFile as any);
        }

        const response = await this.request({
            path: `/attachment/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     */
    async upload(code: number, name: string, multipartFile: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Attachment> {
        const response = await this.uploadRaw({ code: code, name: name, multipartFile: multipartFile }, initOverrides);
        return await response.value();
    }

}
