/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupInputSo
 */
export interface UserGroupInputSo {
    /**
     * 
     * @type {string}
     * @memberof UserGroupInputSo
     */
    preferredUsername: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupInputSo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupInputSo
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupInputSo
     */
    section: Array<string>;
}

/**
 * Check if a given object implements the UserGroupInputSo interface.
 */
export function instanceOfUserGroupInputSo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "preferredUsername" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "section" in value;

    return isInstance;
}

export function UserGroupInputSoFromJSON(json: any): UserGroupInputSo {
    return UserGroupInputSoFromJSONTyped(json, false);
}

export function UserGroupInputSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupInputSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preferredUsername': json['preferredUsername'],
        'name': json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'section': json['section'],
    };
}

export function UserGroupInputSoToJSON(value?: UserGroupInputSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preferredUsername': value.preferredUsername,
        'name': value.name,
        'note': value.note,
        'section': value.section,
    };
}

