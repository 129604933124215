/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressCodelistItem } from './AddressCodelistItem';
import {
    AddressCodelistItemFromJSON,
    AddressCodelistItemFromJSONTyped,
    AddressCodelistItemToJSON,
} from './AddressCodelistItem';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';

/**
 * 
 * @export
 * @interface OperatorResult
 */
export interface OperatorResult {
    /**
     * 
     * @type {number}
     * @memberof OperatorResult
     */
    technicalCode: number | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    operatorType: OperatorResultOperatorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OperatorResult
     */
    ippc: number | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    ico: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    dic: string | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof OperatorResult
     */
    naceType: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof OperatorResult
     */
    nace2Type: CodelistItem | null;
    /**
     * 
     * @type {AddressCodelistItem}
     * @memberof OperatorResult
     */
    address: AddressCodelistItem | null;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorResult
     */
    notificationDuty: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OperatorResult
     */
    evaluated: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    note: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {number}
     * @memberof OperatorResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    code: string;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof OperatorResult
     */
    contactPerson?: Array<ContactPerson>;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof OperatorResult
     */
    technicalAttribute: TechnicalAttribute;
    /**
     * 
     * @type {string}
     * @memberof OperatorResult
     */
    status: OperatorResultStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof OperatorResult
     */
    installationCount: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorResult
     */
    shmuId?: number | null;
}


/**
 * @export
 */
export const OperatorResultOperatorTypeEnum = {
    P: 'P',
    F: 'F',
    Z: 'Z'
} as const;
export type OperatorResultOperatorTypeEnum = typeof OperatorResultOperatorTypeEnum[keyof typeof OperatorResultOperatorTypeEnum];

/**
 * @export
 */
export const OperatorResultStatusEnum = {
    Delete: 'DELETE',
    Block: 'BLOCK',
    Ok: 'OK',
    Wait: 'WAIT'
} as const;
export type OperatorResultStatusEnum = typeof OperatorResultStatusEnum[keyof typeof OperatorResultStatusEnum];


/**
 * Check if a given object implements the OperatorResult interface.
 */
export function instanceOfOperatorResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "technicalCode" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "operatorType" in value;
    isInstance = isInstance && "ippc" in value;
    isInstance = isInstance && "ico" in value;
    isInstance = isInstance && "dic" in value;
    isInstance = isInstance && "naceType" in value;
    isInstance = isInstance && "nace2Type" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "notificationDuty" in value;
    isInstance = isInstance && "evaluated" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "technicalAttribute" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "installationCount" in value;

    return isInstance;
}

export function OperatorResultFromJSON(json: any): OperatorResult {
    return OperatorResultFromJSONTyped(json, false);
}

export function OperatorResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'technicalCode': json['technicalCode'],
        'name': json['name'],
        'operatorType': json['operatorType'],
        'ippc': json['ippc'],
        'ico': json['ico'],
        'dic': json['dic'],
        'naceType': CodelistItemFromJSON(json['naceType']),
        'nace2Type': CodelistItemFromJSON(json['nace2Type']),
        'address': AddressCodelistItemFromJSON(json['address']),
        'notificationDuty': json['notificationDuty'],
        'evaluated': json['evaluated'],
        'note': json['note'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'id': json['id'],
        'code': json['code'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : ((json['contactPerson'] as Array<any>).map(ContactPersonFromJSON)),
        'technicalAttribute': TechnicalAttributeFromJSON(json['technicalAttribute']),
        'status': json['status'],
        'installationCount': json['installationCount'],
        'shmuId': !exists(json, 'shmuId') ? undefined : json['shmuId'],
    };
}

export function OperatorResultToJSON(value?: OperatorResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'technicalCode': value.technicalCode,
        'name': value.name,
        'operatorType': value.operatorType,
        'ippc': value.ippc,
        'ico': value.ico,
        'dic': value.dic,
        'naceType': CodelistItemToJSON(value.naceType),
        'nace2Type': CodelistItemToJSON(value.nace2Type),
        'address': AddressCodelistItemToJSON(value.address),
        'notificationDuty': value.notificationDuty,
        'evaluated': value.evaluated,
        'note': value.note,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'id': value.id,
        'code': value.code,
        'contactPerson': value.contactPerson === undefined ? undefined : ((value.contactPerson as Array<any>).map(ContactPersonToJSON)),
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
        'status': value.status,
        'installationCount': value.installationCount,
        'shmuId': value.shmuId,
    };
}

