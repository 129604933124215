/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileControlResponse
 */
export interface FileControlResponse {
    /**
     * 
     * @type {string}
     * @memberof FileControlResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FileControlResponse
     */
    controlType?: FileControlResponseControlTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FileControlResponse
     */
    controlCriticalityType?: FileControlResponseControlCriticalityTypeEnum;
}


/**
 * @export
 */
export const FileControlResponseControlTypeEnum = {
    FileStructureControl: 'FILE_STRUCTURE_CONTROL',
    FileExtensionControl: 'FILE_EXTENSION_CONTROL',
    FileNameControl: 'FILE_NAME_CONTROL',
    HeaderControl: 'HEADER_CONTROL',
    LaboratoryControl: 'LABORATORY_CONTROL',
    ComparisonOfDeterminants: 'COMPARISON_OF_DETERMINANTS',
    AmountOfWaterTakenFilledEveryMonth: 'AMOUNT_OF_WATER_TAKEN_FILLED_EVERY_MONTH',
    ValueOfDeterminantInVNotHigherInP: 'VALUE_OF_DETERMINANT_IN_V_NOT_HIGHER_IN_P',
    AmountOfWaterDischargedFilledEveryMonth: 'AMOUNT_OF_WATER_DISCHARGED_FILLED_EVERY_MONTH',
    CodelistDeterminantControl: 'CODELIST_DETERMINANT_CONTROL',
    DuplicityNecSampleTypeDate: 'DUPLICITY_NEC_SAMPLE_TYPE_DATE',
    DuplicityNecDeterminantPV: 'DUPLICITY_NEC_DETERMINANT_P_V',
    ComplianceOfSampleDatesWithYear: 'COMPLIANCE_OF_SAMPLE_DATES_WITH_YEAR',
    DuplicitySampleTypeDate: 'DUPLICITY_SAMPLE_TYPE_DATE',
    TerrainAndLaboratoryDateMismatch: 'TERRAIN_AND_LABORATORY_DATE_MISMATCH',
    WeeklyWaterTemperatureWithinTimeSeries: 'WEEKLY_WATER_TEMPERATURE_WITHIN_TIME_SERIES',
    WeeklyWaterLevelWithinTimeSeries: 'WEEKLY_WATER_LEVEL_WITHIN_TIME_SERIES',
    HourlyWaterLevelWithinTimeSeries: 'HOURLY_WATER_LEVEL_WITHIN_TIME_SERIES',
    HourlyWaterTemperatureWithinTimeSeries: 'HOURLY_WATER_TEMPERATURE_WITHIN_TIME_SERIES',
    ValueExceededExtreme: 'VALUE_EXCEEDED_EXTREME',
    WaterLevelEmptyTemperatureEmpty: 'WATER_LEVEL_EMPTY_TEMPERATURE_EMPTY',
    YieldOfSpringEmptyTemperatureEmpty: 'YIELD_OF_SPRING_EMPTY_TEMPERATURE_EMPTY',
    YieldOfSpringRange: 'YIELD_OF_SPRING_RANGE',
    StateOfWaterInProbeRange: 'STATE_OF_WATER_IN_PROBE_RANGE',
    WaterTemperatureRangeUndergroundWater: 'WATER_TEMPERATURE_RANGE_UNDERGROUND_WATER',
    NonExistenceMonPointCode: 'NON_EXISTENCE_MON_POINT_CODE',
    NonExistenceBalanceCode: 'NON_EXISTENCE_BALANCE_CODE',
    BalanceCodeBlockedByDate: 'BALANCE_CODE_BLOCKED_BY_DATE',
    NoMonitoringActivityOnMonPoint: 'NO_MONITORING_ACTIVITY_ON_MON_POINT',
    LoqAgainstDevDetectLimit: 'LOQ_AGAINST_DEV_DETECT_LIMIT',
    LoqAgainstChvoDeterminantLimit: 'LOQ_AGAINST_CHVO_DETERMINANT_LIMIT',
    LoqAgainstAnMethodic: 'LOQ_AGAINST_AN_METHODIC',
    TnmnAnalysisMissingMandatoryData: 'TNMN_ANALYSIS_MISSING_MANDATORY_DATA',
    ExistenceSampleWhenUploadingAnalyses: 'EXISTENCE_SAMPLE_WHEN_UPLOADING_ANALYSES',
    OrganicComponentRange: 'ORGANIC_COMPONENT_RANGE',
    TurbidityRange: 'TURBIDITY_RANGE',
    FlowRange: 'FLOW_RANGE',
    WaterLevelRange: 'WATER_LEVEL_RANGE',
    DrySeason: 'DRY_SEASON',
    WaterTemperatureRangeSurfaceWater: 'WATER_TEMPERATURE_RANGE_SURFACE_WATER',
    NonCriticalWaterTemperatureRangeSurfaceWater: 'NON_CRITICAL_WATER_TEMPERATURE_RANGE_SURFACE_WATER',
    NonCriticalWaterTemperatureRangeUndergroundWater: 'NON_CRITICAL_WATER_TEMPERATURE_RANGE_UNDERGROUND_WATER',
    NonCriticalYieldOfSpringRange: 'NON_CRITICAL_YIELD_OF_SPRING_RANGE',
    StateOfWaterInProbeDepthOfWellAndHighOfMeasuringPoint: 'STATE_OF_WATER_IN_PROBE_DEPTH_OF_WELL_AND_HIGH_OF_MEASURING_POINT',
    NonCriticalStateOfWaterInProbeRange: 'NON_CRITICAL_STATE_OF_WATER_IN_PROBE_RANGE',
    CationAnionTest: 'CATION_ANION_TEST',
    SigmaTest: 'SIGMA_TEST',
    ExistenceDateOfDataInTimePeriodOfMountPointVersion: 'EXISTENCE_DATE_OF_DATA_IN_TIME_PERIOD_OF_MOUNT_POINT_VERSION',
    TotalAndDissolvedMetals: 'TOTAL_AND_DISSOLVED_METALS',
    BskChsk: 'BSK_CHSK',
    NitrogenBalance: 'NITROGEN_BALANCE',
    ComplianceWithGenerallyApplicableChemicalRules: 'COMPLIANCE_WITH_GENERALLY_APPLICABLE_CHEMICAL_RULES',
    ZeroAnalysisValues: 'ZERO_ANALYSIS_VALUES',
    DateFormat: 'DATE_FORMAT',
    MountPointNoVersion: 'MOUNT_POINT_NO_VERSION',
    CorrectnessOfUnit: 'CORRECTNESS_OF_UNIT',
    ExtremeData: 'EXTREME_DATA',
    NumericalDataOutsideAllowedRange: 'NUMERICAL_DATA_OUTSIDE_ALLOWED_RANGE',
    InvalidDateRange: 'INVALID_DATE_RANGE',
    UnknownCodeitemCode: 'UNKNOWN_CODEITEM_CODE',
    UnknownMountPointCode: 'UNKNOWN_MOUNT_POINT_CODE',
    DecimalSeparatorInterpretation: 'DECIMAL_SEPARATOR_INTERPRETATION',
    ImportFileDataRegistryDuplicity: 'IMPORT_FILE_DATA_REGISTRY_DUPLICITY',
    IncompleteTimeSeries: 'INCOMPLETE_TIME_SERIES',
    WarningImportFileRowDuplicity: 'WARNING_IMPORT_FILE_ROW_DUPLICITY',
    ImportFileRowDuplicityKeyAttributes: 'IMPORT_FILE_ROW_DUPLICITY_KEY_ATTRIBUTES',
    IncorrectDataType: 'INCORRECT_DATA_TYPE',
    ValueExceededExtremeQuantityUndergroundWater: 'VALUE_EXCEEDED_EXTREME_QUANTITY_UNDERGROUND_WATER',
    ValueExceededExtremeQuantitySurfaceWater: 'VALUE_EXCEEDED_EXTREME_QUANTITY_SURFACE_WATER',
    WaterLevelEmptyTemperatureEmptyDr: 'WATER_LEVEL_EMPTY_TEMPERATURE_EMPTY_DR',
    YieldOfSpringEmptyTemperatureEmptyDr: 'YIELD_OF_SPRING_EMPTY_TEMPERATURE_EMPTY_DR',
    DeterminantInDeterminantGroup: 'DETERMINANT_IN_DETERMINANT_GROUP',
    ValidityOfNotificationWithNotificationOfCommencement: 'VALIDITY_OF_NOTIFICATION_WITH_NOTIFICATION_OF_COMMENCEMENT',
    DeterminantIsNotZero: 'DETERMINANT_IS_NOT_ZERO',
    DeterminantIsNotZeroImport: 'DETERMINANT_IS_NOT_ZERO_IMPORT',
    ImportFileDataRegistryDuplicityTransposed: 'IMPORT_FILE_DATA_REGISTRY_DUPLICITY_TRANSPOSED',
    ImportFileDataRegistryDuplicityMultipleValue: 'IMPORT_FILE_DATA_REGISTRY_DUPLICITY_MULTIPLE_VALUE',
    AtLeastOneOfNParametersMustBeFilled: 'AT_LEAST_ONE_OF_N_PARAMETERS_MUST_BE_FILLED',
    MonitoringActivityIsActiveInGivenYear: 'MONITORING_ACTIVITY_IS_ACTIVE_IN_GIVEN_YEAR',
    ParameterMustBeFilledIfAtLeastOneOfTheListOfParametersIsFilledOut: 'PARAMETER_MUST_BE_FILLED_IF_AT_LEAST_ONE_OF_THE_LIST_OF_PARAMETERS_IS_FILLED_OUT'
} as const;
export type FileControlResponseControlTypeEnum = typeof FileControlResponseControlTypeEnum[keyof typeof FileControlResponseControlTypeEnum];

/**
 * @export
 */
export const FileControlResponseControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING'
} as const;
export type FileControlResponseControlCriticalityTypeEnum = typeof FileControlResponseControlCriticalityTypeEnum[keyof typeof FileControlResponseControlCriticalityTypeEnum];


/**
 * Check if a given object implements the FileControlResponse interface.
 */
export function instanceOfFileControlResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FileControlResponseFromJSON(json: any): FileControlResponse {
    return FileControlResponseFromJSONTyped(json, false);
}

export function FileControlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileControlResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'controlType': !exists(json, 'controlType') ? undefined : json['controlType'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
    };
}

export function FileControlResponseToJSON(value?: FileControlResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'controlType': value.controlType,
        'controlCriticalityType': value.controlCriticalityType,
    };
}

