/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BalanceCodeResult
 */
export interface BalanceCodeResult {
    /**
     * 
     * @type {string}
     * @memberof BalanceCodeResult
     */
    balanceCode?: string;
}

/**
 * Check if a given object implements the BalanceCodeResult interface.
 */
export function instanceOfBalanceCodeResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BalanceCodeResultFromJSON(json: any): BalanceCodeResult {
    return BalanceCodeResultFromJSONTyped(json, false);
}

export function BalanceCodeResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceCodeResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balanceCode': !exists(json, 'balanceCode') ? undefined : json['balanceCode'],
    };
}

export function BalanceCodeResultToJSON(value?: BalanceCodeResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balanceCode': value.balanceCode,
    };
}

