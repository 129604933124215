/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Criteria containing:
 *  - document id for which validation report is generated
 *  - control criticality type - all errors on a row will be included if the row contains at least one error
 *  with specified criticality level, if omited all errors on all rows will be included in a report
 * 
 * @export
 * @interface ValidationReportRowCriteriaRequest
 */
export interface ValidationReportRowCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportRowCriteriaRequest
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportRowCriteriaRequest
     */
    controlCriticalityType?: ValidationReportRowCriteriaRequestControlCriticalityTypeEnum;
}


/**
 * @export
 */
export const ValidationReportRowCriteriaRequestControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING'
} as const;
export type ValidationReportRowCriteriaRequestControlCriticalityTypeEnum = typeof ValidationReportRowCriteriaRequestControlCriticalityTypeEnum[keyof typeof ValidationReportRowCriteriaRequestControlCriticalityTypeEnum];


/**
 * Check if a given object implements the ValidationReportRowCriteriaRequest interface.
 */
export function instanceOfValidationReportRowCriteriaRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationReportRowCriteriaRequestFromJSON(json: any): ValidationReportRowCriteriaRequest {
    return ValidationReportRowCriteriaRequestFromJSONTyped(json, false);
}

export function ValidationReportRowCriteriaRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportRowCriteriaRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
    };
}

export function ValidationReportRowCriteriaRequestToJSON(value?: ValidationReportRowCriteriaRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'controlCriticalityType': value.controlCriticalityType,
    };
}

