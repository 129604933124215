/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SortObject } from './SortObject';
import {
    SortObjectFromJSON,
    SortObjectFromJSONTyped,
    SortObjectToJSON,
} from './SortObject';

/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    sort?: SortObject;
}

/**
 * Check if a given object implements the PageableObject interface.
 */
export function instanceOfPageableObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageableObjectFromJSON(json: any): PageableObject {
    return PageableObjectFromJSONTyped(json, false);
}

export function PageableObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageableObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unpaged': !exists(json, 'unpaged') ? undefined : json['unpaged'],
        'paged': !exists(json, 'paged') ? undefined : json['paged'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'sort': !exists(json, 'sort') ? undefined : SortObjectFromJSON(json['sort']),
    };
}

export function PageableObjectToJSON(value?: PageableObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unpaged': value.unpaged,
        'paged': value.paged,
        'pageSize': value.pageSize,
        'pageNumber': value.pageNumber,
        'offset': value.offset,
        'sort': SortObjectToJSON(value.sort),
    };
}

