/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { MethodicTechnicalAttribute } from './MethodicTechnicalAttribute';
import {
    MethodicTechnicalAttributeFromJSON,
    MethodicTechnicalAttributeFromJSONTyped,
    MethodicTechnicalAttributeToJSON,
} from './MethodicTechnicalAttribute';

/**
 * 
 * @export
 * @interface MethodicResult
 */
export interface MethodicResult {
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    sensibility: number | null;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    detectionLimit: number | null;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    uncertaintyFmt: number | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MethodicResult
     */
    institutionType: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof MethodicResult
     */
    laboratory: string | null;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    limitValue: number | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MethodicResult
     */
    anMethodType: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MethodicResult
     */
    anMethodGroupType: CodelistItem | null;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    uncertainty: number | null;
    /**
     * 
     * @type {string}
     * @memberof MethodicResult
     */
    refToLiterature: string | null;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    sensibilityValid: number;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    limitValueValid: number;
    /**
     * 
     * @type {string}
     * @memberof MethodicResult
     */
    note: string | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MethodicResult
     */
    determinantType: CodelistItem | null;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    shmuId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MethodicResult
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {string}
     * @memberof MethodicResult
     */
    effectiveFrom: string | null;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof MethodicResult
     */
    code: number;
    /**
     * 
     * @type {MethodicTechnicalAttribute}
     * @memberof MethodicResult
     */
    technicalAttribute: MethodicTechnicalAttribute;
    /**
     * 
     * @type {boolean}
     * @memberof MethodicResult
     */
    deleted: boolean;
}

/**
 * Check if a given object implements the MethodicResult interface.
 */
export function instanceOfMethodicResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sensibility" in value;
    isInstance = isInstance && "detectionLimit" in value;
    isInstance = isInstance && "uncertaintyFmt" in value;
    isInstance = isInstance && "institutionType" in value;
    isInstance = isInstance && "laboratory" in value;
    isInstance = isInstance && "limitValue" in value;
    isInstance = isInstance && "anMethodType" in value;
    isInstance = isInstance && "anMethodGroupType" in value;
    isInstance = isInstance && "uncertainty" in value;
    isInstance = isInstance && "refToLiterature" in value;
    isInstance = isInstance && "sensibilityValid" in value;
    isInstance = isInstance && "limitValueValid" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "determinantType" in value;
    isInstance = isInstance && "shmuId" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "technicalAttribute" in value;
    isInstance = isInstance && "deleted" in value;

    return isInstance;
}

export function MethodicResultFromJSON(json: any): MethodicResult {
    return MethodicResultFromJSONTyped(json, false);
}

export function MethodicResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodicResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sensibility': json['sensibility'],
        'detectionLimit': json['detectionLimit'],
        'uncertaintyFmt': json['uncertaintyFmt'],
        'institutionType': CodelistItemFromJSON(json['institutionType']),
        'laboratory': json['laboratory'],
        'limitValue': json['limitValue'],
        'anMethodType': CodelistItemFromJSON(json['anMethodType']),
        'anMethodGroupType': CodelistItemFromJSON(json['anMethodGroupType']),
        'uncertainty': json['uncertainty'],
        'refToLiterature': json['refToLiterature'],
        'sensibilityValid': json['sensibilityValid'],
        'limitValueValid': json['limitValueValid'],
        'note': json['note'],
        'determinantType': CodelistItemFromJSON(json['determinantType']),
        'shmuId': json['shmuId'],
        'effectiveTo': json['effectiveTo'],
        'effectiveFrom': json['effectiveFrom'],
        'id': json['id'],
        'code': json['code'],
        'technicalAttribute': MethodicTechnicalAttributeFromJSON(json['technicalAttribute']),
        'deleted': json['deleted'],
    };
}

export function MethodicResultToJSON(value?: MethodicResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sensibility': value.sensibility,
        'detectionLimit': value.detectionLimit,
        'uncertaintyFmt': value.uncertaintyFmt,
        'institutionType': CodelistItemToJSON(value.institutionType),
        'laboratory': value.laboratory,
        'limitValue': value.limitValue,
        'anMethodType': CodelistItemToJSON(value.anMethodType),
        'anMethodGroupType': CodelistItemToJSON(value.anMethodGroupType),
        'uncertainty': value.uncertainty,
        'refToLiterature': value.refToLiterature,
        'sensibilityValid': value.sensibilityValid,
        'limitValueValid': value.limitValueValid,
        'note': value.note,
        'determinantType': CodelistItemToJSON(value.determinantType),
        'shmuId': value.shmuId,
        'effectiveTo': value.effectiveTo,
        'effectiveFrom': value.effectiveFrom,
        'id': value.id,
        'code': value.code,
        'technicalAttribute': MethodicTechnicalAttributeToJSON(value.technicalAttribute),
        'deleted': value.deleted,
    };
}

