/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperatorCriteria
 */
export interface OperatorCriteria {
    /**
     * 
     * @type {string}
     * @memberof OperatorCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorCriteria
     */
    technicalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorCriteria
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorCriteria
     */
    ippc?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorCriteria
     */
    ico?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorCriteria
     */
    dic?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorCriteria
     */
    naceType?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorCriteria
     */
    nace2Type?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperatorCriteria
     */
    notificationDuty?: Array<OperatorCriteriaNotificationDutyEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperatorCriteria
     */
    evaluated?: Array<OperatorCriteriaEvaluatedEnum>;
    /**
     * 
     * @type {string}
     * @memberof OperatorCriteria
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorCriteria
     */
    code?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperatorCriteria
     */
    status?: Array<OperatorCriteriaStatusEnum>;
}


/**
 * @export
 */
export const OperatorCriteriaNotificationDutyEnum = {
    True: 'TRUE',
    False: 'FALSE',
    Undefined: 'UNDEFINED'
} as const;
export type OperatorCriteriaNotificationDutyEnum = typeof OperatorCriteriaNotificationDutyEnum[keyof typeof OperatorCriteriaNotificationDutyEnum];

/**
 * @export
 */
export const OperatorCriteriaEvaluatedEnum = {
    True: 'TRUE',
    False: 'FALSE',
    Undefined: 'UNDEFINED'
} as const;
export type OperatorCriteriaEvaluatedEnum = typeof OperatorCriteriaEvaluatedEnum[keyof typeof OperatorCriteriaEvaluatedEnum];

/**
 * @export
 */
export const OperatorCriteriaStatusEnum = {
    Delete: 'DELETE',
    Block: 'BLOCK',
    Ok: 'OK',
    Wait: 'WAIT'
} as const;
export type OperatorCriteriaStatusEnum = typeof OperatorCriteriaStatusEnum[keyof typeof OperatorCriteriaStatusEnum];


/**
 * Check if a given object implements the OperatorCriteria interface.
 */
export function instanceOfOperatorCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OperatorCriteriaFromJSON(json: any): OperatorCriteria {
    return OperatorCriteriaFromJSONTyped(json, false);
}

export function OperatorCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'technicalCode': !exists(json, 'technicalCode') ? undefined : json['technicalCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ippc': !exists(json, 'ippc') ? undefined : json['ippc'],
        'ico': !exists(json, 'ico') ? undefined : json['ico'],
        'dic': !exists(json, 'dic') ? undefined : json['dic'],
        'naceType': !exists(json, 'naceType') ? undefined : json['naceType'],
        'nace2Type': !exists(json, 'nace2Type') ? undefined : json['nace2Type'],
        'notificationDuty': !exists(json, 'notificationDuty') ? undefined : json['notificationDuty'],
        'evaluated': !exists(json, 'evaluated') ? undefined : json['evaluated'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function OperatorCriteriaToJSON(value?: OperatorCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'technicalCode': value.technicalCode,
        'name': value.name,
        'ippc': value.ippc,
        'ico': value.ico,
        'dic': value.dic,
        'naceType': value.naceType,
        'nace2Type': value.nace2Type,
        'notificationDuty': value.notificationDuty,
        'evaluated': value.evaluated,
        'note': value.note,
        'code': value.code,
        'status': value.status,
    };
}

