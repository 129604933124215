/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { CodelistItemRequired } from './CodelistItemRequired';
import {
    CodelistItemRequiredFromJSON,
    CodelistItemRequiredFromJSONTyped,
    CodelistItemRequiredToJSON,
} from './CodelistItemRequired';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';
import type { Threshold } from './Threshold';
import {
    ThresholdFromJSON,
    ThresholdFromJSONTyped,
    ThresholdToJSON,
} from './Threshold';

/**
 * 
 * @export
 * @interface WaterShapeResult
 */
export interface WaterShapeResult {
    /**
     * 
     * @type {string}
     * @memberof WaterShapeResult
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    riverKmClosest: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    riverKmFurthest: number;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof WaterShapeResult
     */
    basinType: CodelistItemRequired;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof WaterShapeResult
     */
    wbCharacterType: CodelistItemRequired;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof WaterShapeResult
     */
    wbGroupType: CodelistItemRequired;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof WaterShapeResult
     */
    wbType: CodelistItemRequired;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof WaterShapeResult
     */
    cycleType: CodelistItemRequired;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    jtskXStart: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    jtskXEnd: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    jtskYStart: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    jtskYEnd: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeResult
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeResult
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeResult
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof WaterShapeResult
     */
    deleted: boolean;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    version: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    flowLength: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeResult
     */
    statusType: number;
    /**
     * 
     * @type {boolean}
     * @memberof WaterShapeResult
     */
    historical: boolean;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterShapeResult
     */
    wbCategoryType: CodelistItem | null;
    /**
     * 
     * @type {Set<Threshold>}
     * @memberof WaterShapeResult
     */
    thresholds: Set<Threshold>;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof WaterShapeResult
     */
    technicalAttribute: TechnicalAttribute;
}

/**
 * Check if a given object implements the WaterShapeResult interface.
 */
export function instanceOfWaterShapeResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "riverKmClosest" in value;
    isInstance = isInstance && "riverKmFurthest" in value;
    isInstance = isInstance && "basinType" in value;
    isInstance = isInstance && "wbCharacterType" in value;
    isInstance = isInstance && "wbGroupType" in value;
    isInstance = isInstance && "wbType" in value;
    isInstance = isInstance && "cycleType" in value;
    isInstance = isInstance && "jtskXStart" in value;
    isInstance = isInstance && "jtskXEnd" in value;
    isInstance = isInstance && "jtskYStart" in value;
    isInstance = isInstance && "jtskYEnd" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "flowLength" in value;
    isInstance = isInstance && "statusType" in value;
    isInstance = isInstance && "historical" in value;
    isInstance = isInstance && "wbCategoryType" in value;
    isInstance = isInstance && "thresholds" in value;
    isInstance = isInstance && "technicalAttribute" in value;

    return isInstance;
}

export function WaterShapeResultFromJSON(json: any): WaterShapeResult {
    return WaterShapeResultFromJSONTyped(json, false);
}

export function WaterShapeResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterShapeResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'riverKmClosest': json['riverKmClosest'],
        'riverKmFurthest': json['riverKmFurthest'],
        'basinType': CodelistItemRequiredFromJSON(json['basinType']),
        'wbCharacterType': CodelistItemRequiredFromJSON(json['wbCharacterType']),
        'wbGroupType': CodelistItemRequiredFromJSON(json['wbGroupType']),
        'wbType': CodelistItemRequiredFromJSON(json['wbType']),
        'cycleType': CodelistItemRequiredFromJSON(json['cycleType']),
        'jtskXStart': json['jtskXStart'],
        'jtskXEnd': json['jtskXEnd'],
        'jtskYStart': json['jtskYStart'],
        'jtskYEnd': json['jtskYEnd'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'id': json['id'],
        'code': json['code'],
        'deleted': json['deleted'],
        'version': json['version'],
        'flowLength': json['flowLength'],
        'statusType': json['statusType'],
        'historical': json['historical'],
        'wbCategoryType': CodelistItemFromJSON(json['wbCategoryType']),
        'thresholds': (new Set((json['thresholds'] as Array<any>).map(ThresholdFromJSON))),
        'technicalAttribute': TechnicalAttributeFromJSON(json['technicalAttribute']),
    };
}

export function WaterShapeResultToJSON(value?: WaterShapeResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'riverKmClosest': value.riverKmClosest,
        'riverKmFurthest': value.riverKmFurthest,
        'basinType': CodelistItemRequiredToJSON(value.basinType),
        'wbCharacterType': CodelistItemRequiredToJSON(value.wbCharacterType),
        'wbGroupType': CodelistItemRequiredToJSON(value.wbGroupType),
        'wbType': CodelistItemRequiredToJSON(value.wbType),
        'cycleType': CodelistItemRequiredToJSON(value.cycleType),
        'jtskXStart': value.jtskXStart,
        'jtskXEnd': value.jtskXEnd,
        'jtskYStart': value.jtskYStart,
        'jtskYEnd': value.jtskYEnd,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'id': value.id,
        'code': value.code,
        'deleted': value.deleted,
        'version': value.version,
        'flowLength': value.flowLength,
        'statusType': value.statusType,
        'historical': value.historical,
        'wbCategoryType': CodelistItemToJSON(value.wbCategoryType),
        'thresholds': (Array.from(value.thresholds as Set<any>).map(ThresholdToJSON)),
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
    };
}

