/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  EnvironmentExportDataSo,
  ExportProcessInfoSo,
  ImportTemplateResponse,
  InstallationExportDataSo,
  MonitoringPointExportDataSo,
  MonitoringPointTechExportDataSo,
  OperatorDetailExportDataSo,
  OperatorExportDataSo,
  TemplateType,
  WaterShapeExportDataSo,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    EnvironmentExportDataSoFromJSON,
    EnvironmentExportDataSoToJSON,
    ExportProcessInfoSoFromJSON,
    ExportProcessInfoSoToJSON,
    ImportTemplateResponseFromJSON,
    ImportTemplateResponseToJSON,
    InstallationExportDataSoFromJSON,
    InstallationExportDataSoToJSON,
    MonitoringPointExportDataSoFromJSON,
    MonitoringPointExportDataSoToJSON,
    MonitoringPointTechExportDataSoFromJSON,
    MonitoringPointTechExportDataSoToJSON,
    OperatorDetailExportDataSoFromJSON,
    OperatorDetailExportDataSoToJSON,
    OperatorExportDataSoFromJSON,
    OperatorExportDataSoToJSON,
    TemplateTypeFromJSON,
    TemplateTypeToJSON,
    WaterShapeExportDataSoFromJSON,
    WaterShapeExportDataSoToJSON,
} from '../models/index';

export interface ExportApiGetImportTemplateRequest {
    templateType: TemplateType;
    montPointTypeCode: number;
}

export interface ExportApiGetStreamedFileRequest {
    uuid: string;
}

export interface ExportApiRefreshExportProcessInfoRequest {
    uuid: string;
}

export interface ExportApiStreamToEnvironmentExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    environmentExportDataSo: EnvironmentExportDataSo;
}

export interface ExportApiStreamToInstallationExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    installationExportDataSo: InstallationExportDataSo;
}

export interface ExportApiStreamToMonitoringPointExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    monitoringPointExportDataSo: MonitoringPointExportDataSo;
}

export interface ExportApiStreamToMonitoringPointTechExportByCriteriaRequest {
    monitoringPointTechExportDataSo: MonitoringPointTechExportDataSo;
}

export interface ExportApiStreamToOperatorDetailExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    operatorDetailExportDataSo: OperatorDetailExportDataSo;
}

export interface ExportApiStreamToOperatorExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    operatorExportDataSo: OperatorExportDataSo;
}

export interface ExportApiStreamToWaterShapeExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    waterShapeExportDataSo: WaterShapeExportDataSo;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     */
    async getImportTemplateRaw(requestParameters: ExportApiGetImportTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportTemplateResponse>> {
        if (requestParameters.templateType === null || requestParameters.templateType === undefined) {
            throw new runtime.RequiredError('templateType','Required parameter requestParameters.templateType was null or undefined when calling getImportTemplate.');
        }

        if (requestParameters.montPointTypeCode === null || requestParameters.montPointTypeCode === undefined) {
            throw new runtime.RequiredError('montPointTypeCode','Required parameter requestParameters.montPointTypeCode was null or undefined when calling getImportTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.templateType !== undefined) {
            queryParameters['templateType'] = requestParameters.templateType;
        }

        if (requestParameters.montPointTypeCode !== undefined) {
            queryParameters['montPointTypeCode'] = requestParameters.montPointTypeCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/import-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportTemplateResponseFromJSON(jsonValue));
    }

    /**
     */
    async getImportTemplate(templateType: TemplateType, montPointTypeCode: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportTemplateResponse> {
        const response = await this.getImportTemplateRaw({ templateType: templateType, montPointTypeCode: montPointTypeCode }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStreamedFileRaw(requestParameters: ExportApiGetStreamedFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getStreamedFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/streamed-file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getStreamedFile(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getStreamedFileRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshExportProcessInfoRaw(requestParameters: ExportApiRefreshExportProcessInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportProcessInfoSo>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling refreshExportProcessInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/refresh-export-process-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportProcessInfoSoFromJSON(jsonValue));
    }

    /**
     */
    async refreshExportProcessInfo(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportProcessInfoSo> {
        const response = await this.refreshExportProcessInfoRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToEnvironmentExportByCriteriaRaw(requestParameters: ExportApiStreamToEnvironmentExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToEnvironmentExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToEnvironmentExportByCriteria.');
        }

        if (requestParameters.environmentExportDataSo === null || requestParameters.environmentExportDataSo === undefined) {
            throw new runtime.RequiredError('environmentExportDataSo','Required parameter requestParameters.environmentExportDataSo was null or undefined when calling streamToEnvironmentExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-environment-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnvironmentExportDataSoToJSON(requestParameters.environmentExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToEnvironmentExportByCriteria(exportType: string, xlsxAutosize: boolean, environmentExportDataSo: EnvironmentExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToEnvironmentExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, environmentExportDataSo: environmentExportDataSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToInstallationExportByCriteriaRaw(requestParameters: ExportApiStreamToInstallationExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToInstallationExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToInstallationExportByCriteria.');
        }

        if (requestParameters.installationExportDataSo === null || requestParameters.installationExportDataSo === undefined) {
            throw new runtime.RequiredError('installationExportDataSo','Required parameter requestParameters.installationExportDataSo was null or undefined when calling streamToInstallationExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-installation-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstallationExportDataSoToJSON(requestParameters.installationExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToInstallationExportByCriteria(exportType: string, xlsxAutosize: boolean, installationExportDataSo: InstallationExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToInstallationExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, installationExportDataSo: installationExportDataSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToMonitoringPointExportByCriteriaRaw(requestParameters: ExportApiStreamToMonitoringPointExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToMonitoringPointExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToMonitoringPointExportByCriteria.');
        }

        if (requestParameters.monitoringPointExportDataSo === null || requestParameters.monitoringPointExportDataSo === undefined) {
            throw new runtime.RequiredError('monitoringPointExportDataSo','Required parameter requestParameters.monitoringPointExportDataSo was null or undefined when calling streamToMonitoringPointExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-monitoring-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointExportDataSoToJSON(requestParameters.monitoringPointExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToMonitoringPointExportByCriteria(exportType: string, xlsxAutosize: boolean, monitoringPointExportDataSo: MonitoringPointExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToMonitoringPointExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, monitoringPointExportDataSo: monitoringPointExportDataSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToMonitoringPointTechExportByCriteriaRaw(requestParameters: ExportApiStreamToMonitoringPointTechExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.monitoringPointTechExportDataSo === null || requestParameters.monitoringPointTechExportDataSo === undefined) {
            throw new runtime.RequiredError('monitoringPointTechExportDataSo','Required parameter requestParameters.monitoringPointTechExportDataSo was null or undefined when calling streamToMonitoringPointTechExportByCriteria.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-monitoring-tech-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointTechExportDataSoToJSON(requestParameters.monitoringPointTechExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToMonitoringPointTechExportByCriteria(monitoringPointTechExportDataSo: MonitoringPointTechExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToMonitoringPointTechExportByCriteriaRaw({ monitoringPointTechExportDataSo: monitoringPointTechExportDataSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToOperatorDetailExportByCriteriaRaw(requestParameters: ExportApiStreamToOperatorDetailExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToOperatorDetailExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToOperatorDetailExportByCriteria.');
        }

        if (requestParameters.operatorDetailExportDataSo === null || requestParameters.operatorDetailExportDataSo === undefined) {
            throw new runtime.RequiredError('operatorDetailExportDataSo','Required parameter requestParameters.operatorDetailExportDataSo was null or undefined when calling streamToOperatorDetailExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-operator-detail-export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorDetailExportDataSoToJSON(requestParameters.operatorDetailExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToOperatorDetailExportByCriteria(exportType: string, xlsxAutosize: boolean, operatorDetailExportDataSo: OperatorDetailExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToOperatorDetailExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, operatorDetailExportDataSo: operatorDetailExportDataSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToOperatorExportByCriteriaRaw(requestParameters: ExportApiStreamToOperatorExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToOperatorExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToOperatorExportByCriteria.');
        }

        if (requestParameters.operatorExportDataSo === null || requestParameters.operatorExportDataSo === undefined) {
            throw new runtime.RequiredError('operatorExportDataSo','Required parameter requestParameters.operatorExportDataSo was null or undefined when calling streamToOperatorExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-operator-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorExportDataSoToJSON(requestParameters.operatorExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToOperatorExportByCriteria(exportType: string, xlsxAutosize: boolean, operatorExportDataSo: OperatorExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToOperatorExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, operatorExportDataSo: operatorExportDataSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToWaterShapeExportByCriteriaRaw(requestParameters: ExportApiStreamToWaterShapeExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToWaterShapeExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToWaterShapeExportByCriteria.');
        }

        if (requestParameters.waterShapeExportDataSo === null || requestParameters.waterShapeExportDataSo === undefined) {
            throw new runtime.RequiredError('waterShapeExportDataSo','Required parameter requestParameters.waterShapeExportDataSo was null or undefined when calling streamToWaterShapeExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-water-shape-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterShapeExportDataSoToJSON(requestParameters.waterShapeExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToWaterShapeExportByCriteria(exportType: string, xlsxAutosize: boolean, waterShapeExportDataSo: WaterShapeExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToWaterShapeExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, waterShapeExportDataSo: waterShapeExportDataSo }, initOverrides);
        return await response.value();
    }

}
