/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeCriteria
 */
export interface AttributeCriteria {
    /**
     * 
     * @type {string}
     * @memberof AttributeCriteria
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeCriteria
     */
    value?: string;
}

/**
 * Check if a given object implements the AttributeCriteria interface.
 */
export function instanceOfAttributeCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttributeCriteriaFromJSON(json: any): AttributeCriteria {
    return AttributeCriteriaFromJSONTyped(json, false);
}

export function AttributeCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function AttributeCriteriaToJSON(value?: AttributeCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}

