/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteCodelistsInput
 */
export interface DeleteCodelistsInput {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteCodelistsInput
     */
    codes: Array<number>;
}

/**
 * Check if a given object implements the DeleteCodelistsInput interface.
 */
export function instanceOfDeleteCodelistsInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "codes" in value;

    return isInstance;
}

export function DeleteCodelistsInputFromJSON(json: any): DeleteCodelistsInput {
    return DeleteCodelistsInputFromJSONTyped(json, false);
}

export function DeleteCodelistsInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCodelistsInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codes': json['codes'],
    };
}

export function DeleteCodelistsInputToJSON(value?: DeleteCodelistsInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codes': value.codes,
    };
}

