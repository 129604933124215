/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemRequired } from './CodelistItemRequired';
import {
    CodelistItemRequiredFromJSON,
    CodelistItemRequiredFromJSONTyped,
    CodelistItemRequiredToJSON,
} from './CodelistItemRequired';
import type { WaterShapeSimple } from './WaterShapeSimple';
import {
    WaterShapeSimpleFromJSON,
    WaterShapeSimpleFromJSONTyped,
    WaterShapeSimpleToJSON,
} from './WaterShapeSimple';

/**
 * 
 * @export
 * @interface ThresholdResult
 */
export interface ThresholdResult {
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof ThresholdResult
     */
    determinantType: CodelistItemRequired;
    /**
     * 
     * @type {number}
     * @memberof ThresholdResult
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdResult
     */
    valueType: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdResult
     */
    id: number;
    /**
     * 
     * @type {WaterShapeSimple}
     * @memberof ThresholdResult
     */
    waterShape?: WaterShapeSimple;
}

/**
 * Check if a given object implements the ThresholdResult interface.
 */
export function instanceOfThresholdResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "determinantType" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "valueType" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ThresholdResultFromJSON(json: any): ThresholdResult {
    return ThresholdResultFromJSONTyped(json, false);
}

export function ThresholdResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThresholdResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'determinantType': CodelistItemRequiredFromJSON(json['determinantType']),
        'value': json['value'],
        'valueType': json['valueType'],
        'id': json['id'],
        'waterShape': !exists(json, 'waterShape') ? undefined : WaterShapeSimpleFromJSON(json['waterShape']),
    };
}

export function ThresholdResultToJSON(value?: ThresholdResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'determinantType': CodelistItemRequiredToJSON(value.determinantType),
        'value': value.value,
        'valueType': value.valueType,
        'id': value.id,
        'waterShape': WaterShapeSimpleToJSON(value.waterShape),
    };
}

