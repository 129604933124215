/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Methodic
 */
export interface Methodic {
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    methodic?: number;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    methodCodeInt?: string;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    methodGroup?: number;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    detCodeInt?: string;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    determinant?: number;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    method?: number;
}

/**
 * Check if a given object implements the Methodic interface.
 */
export function instanceOfMethodic(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MethodicFromJSON(json: any): Methodic {
    return MethodicFromJSONTyped(json, false);
}

export function MethodicFromJSONTyped(json: any, ignoreDiscriminator: boolean): Methodic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'methodic': !exists(json, 'methodic') ? undefined : json['methodic'],
        'methodCodeInt': !exists(json, 'methodCodeInt') ? undefined : json['methodCodeInt'],
        'methodGroup': !exists(json, 'methodGroup') ? undefined : json['methodGroup'],
        'detCodeInt': !exists(json, 'detCodeInt') ? undefined : json['detCodeInt'],
        'determinant': !exists(json, 'determinant') ? undefined : json['determinant'],
        'method': !exists(json, 'method') ? undefined : json['method'],
    };
}

export function MethodicToJSON(value?: Methodic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'methodic': value.methodic,
        'methodCodeInt': value.methodCodeInt,
        'methodGroup': value.methodGroup,
        'detCodeInt': value.detCodeInt,
        'determinant': value.determinant,
        'method': value.method,
    };
}

