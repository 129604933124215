/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeCriteria } from './AttributeCriteria';
import {
    AttributeCriteriaFromJSON,
    AttributeCriteriaFromJSONTyped,
    AttributeCriteriaToJSON,
} from './AttributeCriteria';

/**
 * 
 * @export
 * @interface CodelistItemCriteria
 */
export interface CodelistItemCriteria {
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof CodelistItemCriteria
     */
    codelistCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    codelistAcronym?: string;
    /**
     * 
     * @type {number}
     * @memberof CodelistItemCriteria
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    acronym?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    shortenedName?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    abbreviatedName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistItemCriteria
     */
    deleted?: boolean;
    /**
     * 
     * @type {Array<AttributeCriteria>}
     * @memberof CodelistItemCriteria
     */
    attribute?: Array<AttributeCriteria>;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    validFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    effectiveTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistItemCriteria
     */
    historical?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof CodelistItemCriteria
     */
    userGroupIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CodelistItemCriteria
     */
    codes?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CodelistItemCriteria
     */
    searchFields?: Array<CodelistItemCriteriaSearchFieldsEnum>;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    rangeDateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemCriteria
     */
    rangeDateTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistItemCriteria
     */
    useEqual?: boolean;
}


/**
 * @export
 */
export const CodelistItemCriteriaSearchFieldsEnum = {
    Code: 'code',
    Acronym: 'acronym',
    Name: 'name',
    ShortenedName: 'shortenedName',
    AbbreviatedName: 'abbreviatedName'
} as const;
export type CodelistItemCriteriaSearchFieldsEnum = typeof CodelistItemCriteriaSearchFieldsEnum[keyof typeof CodelistItemCriteriaSearchFieldsEnum];


/**
 * Check if a given object implements the CodelistItemCriteria interface.
 */
export function instanceOfCodelistItemCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CodelistItemCriteriaFromJSON(json: any): CodelistItemCriteria {
    return CodelistItemCriteriaFromJSONTyped(json, false);
}

export function CodelistItemCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItemCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'codelistCode': !exists(json, 'codelistCode') ? undefined : json['codelistCode'],
        'codelistAcronym': !exists(json, 'codelistAcronym') ? undefined : json['codelistAcronym'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'acronym': !exists(json, 'acronym') ? undefined : json['acronym'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortenedName': !exists(json, 'shortenedName') ? undefined : json['shortenedName'],
        'abbreviatedName': !exists(json, 'abbreviatedName') ? undefined : json['abbreviatedName'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'attribute': !exists(json, 'attribute') ? undefined : ((json['attribute'] as Array<any>).map(AttributeCriteriaFromJSON)),
        'validFrom': !exists(json, 'validFrom') ? undefined : json['validFrom'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'historical': !exists(json, 'historical') ? undefined : json['historical'],
        'userGroupIds': !exists(json, 'userGroupIds') ? undefined : json['userGroupIds'],
        'codes': !exists(json, 'codes') ? undefined : json['codes'],
        'searchFields': !exists(json, 'searchFields') ? undefined : json['searchFields'],
        'rangeDateFrom': !exists(json, 'rangeDateFrom') ? undefined : json['rangeDateFrom'],
        'rangeDateTo': !exists(json, 'rangeDateTo') ? undefined : json['rangeDateTo'],
        'useEqual': !exists(json, 'useEqual') ? undefined : json['useEqual'],
    };
}

export function CodelistItemCriteriaToJSON(value?: CodelistItemCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'codelistCode': value.codelistCode,
        'codelistAcronym': value.codelistAcronym,
        'code': value.code,
        'acronym': value.acronym,
        'name': value.name,
        'shortenedName': value.shortenedName,
        'abbreviatedName': value.abbreviatedName,
        'deleted': value.deleted,
        'attribute': value.attribute === undefined ? undefined : ((value.attribute as Array<any>).map(AttributeCriteriaToJSON)),
        'validFrom': value.validFrom,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'historical': value.historical,
        'userGroupIds': value.userGroupIds,
        'codes': value.codes,
        'searchFields': value.searchFields,
        'rangeDateFrom': value.rangeDateFrom,
        'rangeDateTo': value.rangeDateTo,
        'useEqual': value.useEqual,
    };
}

