/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';
import type { CodelistItemAttribute } from './CodelistItemAttribute';
import {
    CodelistItemAttributeFromJSON,
    CodelistItemAttributeFromJSONTyped,
    CodelistItemAttributeToJSON,
} from './CodelistItemAttribute';
import type { CodelistItemLocalization } from './CodelistItemLocalization';
import {
    CodelistItemLocalizationFromJSON,
    CodelistItemLocalizationFromJSONTyped,
    CodelistItemLocalizationToJSON,
} from './CodelistItemLocalization';

/**
 * 
 * @export
 * @interface CodelistItem
 */
export interface CodelistItem {
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    creator: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    modifier: string | null;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    modified: string | null;
    /**
     * 
     * @type {number}
     * @memberof CodelistItem
     */
    codelistCode: number;
    /**
     * 
     * @type {number}
     * @memberof CodelistItem
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    acronym: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    validFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {Array<CodelistItemAttribute>}
     * @memberof CodelistItem
     */
    attributes: Array<CodelistItemAttribute>;
    /**
     * 
     * @type {Attachment}
     * @memberof CodelistItem
     */
    attachment: Attachment | null;
    /**
     * 
     * @type {Array<CodelistItemLocalization>}
     * @memberof CodelistItem
     */
    localizations: Array<CodelistItemLocalization>;
    /**
     * 
     * @type {string}
     * @memberof CodelistItem
     */
    status: CodelistItemStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CodelistItem
     */
    deleted: boolean;
    /**
     * 
     * @type {number}
     * @memberof CodelistItem
     */
    version: number;
}


/**
 * @export
 */
export const CodelistItemStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Waiting: 'WAITING',
    Deleted: 'DELETED'
} as const;
export type CodelistItemStatusEnum = typeof CodelistItemStatusEnum[keyof typeof CodelistItemStatusEnum];


/**
 * Check if a given object implements the CodelistItem interface.
 */
export function instanceOfCodelistItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modifier" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "codelistCode" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "acronym" in value;
    isInstance = isInstance && "validFrom" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "attributes" in value;
    isInstance = isInstance && "attachment" in value;
    isInstance = isInstance && "localizations" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function CodelistItemFromJSON(json: any): CodelistItem {
    return CodelistItemFromJSONTyped(json, false);
}

export function CodelistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': json['creator'],
        'created': json['created'],
        'modifier': json['modifier'],
        'modified': json['modified'],
        'codelistCode': json['codelistCode'],
        'code': json['code'],
        'acronym': json['acronym'],
        'validFrom': json['validFrom'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'attributes': ((json['attributes'] as Array<any>).map(CodelistItemAttributeFromJSON)),
        'attachment': AttachmentFromJSON(json['attachment']),
        'localizations': ((json['localizations'] as Array<any>).map(CodelistItemLocalizationFromJSON)),
        'status': json['status'],
        'deleted': json['deleted'],
        'version': json['version'],
    };
}

export function CodelistItemToJSON(value?: CodelistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': value.creator,
        'created': value.created,
        'modifier': value.modifier,
        'modified': value.modified,
        'codelistCode': value.codelistCode,
        'code': value.code,
        'acronym': value.acronym,
        'validFrom': value.validFrom,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'attributes': ((value.attributes as Array<any>).map(CodelistItemAttributeToJSON)),
        'attachment': AttachmentToJSON(value.attachment),
        'localizations': ((value.localizations as Array<any>).map(CodelistItemLocalizationToJSON)),
        'status': value.status,
        'deleted': value.deleted,
        'version': value.version,
    };
}

