/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodelistItemWithAttributes
 */
export interface CodelistItemWithAttributes {
    /**
     * 
     * @type {number}
     * @memberof CodelistItemWithAttributes
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemWithAttributes
     */
    acronym: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemWithAttributes
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemWithAttributes
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CodelistItemWithAttributes
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CodelistItemWithAttributes
     */
    attributes?: { [key: string]: string; };
}

/**
 * Check if a given object implements the CodelistItemWithAttributes interface.
 */
export function instanceOfCodelistItemWithAttributes(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "acronym" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;

    return isInstance;
}

export function CodelistItemWithAttributesFromJSON(json: any): CodelistItemWithAttributes {
    return CodelistItemWithAttributesFromJSONTyped(json, false);
}

export function CodelistItemWithAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItemWithAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'acronym': json['acronym'],
        'name': json['name'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function CodelistItemWithAttributesToJSON(value?: CodelistItemWithAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'acronym': value.acronym,
        'name': value.name,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'attributes': value.attributes,
    };
}

