/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemRequired } from './CodelistItemRequired';
import {
    CodelistItemRequiredFromJSON,
    CodelistItemRequiredFromJSONTyped,
    CodelistItemRequiredToJSON,
} from './CodelistItemRequired';
import type { Level } from './Level';
import {
    LevelFromJSON,
    LevelFromJSONTyped,
    LevelToJSON,
} from './Level';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';

/**
 * 
 * @export
 * @interface EnvironmentResult
 */
export interface EnvironmentResult {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    internationalCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    internationalName: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    nameForReport: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    nameForGis: string | null;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof EnvironmentResult
     */
    basinType: CodelistItemRequired;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    managerCode: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentResult
     */
    hierarchicalLevel: number;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof EnvironmentResult
     */
    positionType: CodelistItemRequired;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    mapNumber: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentResult
     */
    length: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    hydrologicalNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    vhpid: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    note: string | null;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof EnvironmentResult
     */
    environmentType: CodelistItemRequired;
    /**
     * 
     * @type {CodelistItemRequired}
     * @memberof EnvironmentResult
     */
    environmentFormType: CodelistItemRequired;
    /**
     * 
     * @type {Level}
     * @memberof EnvironmentResult
     */
    level: Level | null;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentResult
     */
    code: string;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof EnvironmentResult
     */
    technicalAttribute: TechnicalAttribute;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentResult
     */
    deleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentResult
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentResult
     */
    historical: boolean;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentResult
     */
    version: number;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentResult
     */
    shmuId?: number | null;
}

/**
 * Check if a given object implements the EnvironmentResult interface.
 */
export function instanceOfEnvironmentResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "internationalCode" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "internationalName" in value;
    isInstance = isInstance && "nameForReport" in value;
    isInstance = isInstance && "nameForGis" in value;
    isInstance = isInstance && "basinType" in value;
    isInstance = isInstance && "managerCode" in value;
    isInstance = isInstance && "hierarchicalLevel" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "mapNumber" in value;
    isInstance = isInstance && "length" in value;
    isInstance = isInstance && "hydrologicalNumber" in value;
    isInstance = isInstance && "vhpid" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "environmentType" in value;
    isInstance = isInstance && "environmentFormType" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "technicalAttribute" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "historical" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function EnvironmentResultFromJSON(json: any): EnvironmentResult {
    return EnvironmentResultFromJSONTyped(json, false);
}

export function EnvironmentResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'internationalCode': json['internationalCode'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'internationalName': json['internationalName'],
        'nameForReport': json['nameForReport'],
        'nameForGis': json['nameForGis'],
        'basinType': CodelistItemRequiredFromJSON(json['basinType']),
        'managerCode': json['managerCode'],
        'hierarchicalLevel': json['hierarchicalLevel'],
        'positionType': CodelistItemRequiredFromJSON(json['positionType']),
        'mapNumber': json['mapNumber'],
        'length': json['length'],
        'hydrologicalNumber': json['hydrologicalNumber'],
        'vhpid': json['vhpid'],
        'note': json['note'],
        'environmentType': CodelistItemRequiredFromJSON(json['environmentType']),
        'environmentFormType': CodelistItemRequiredFromJSON(json['environmentFormType']),
        'level': LevelFromJSON(json['level']),
        'id': json['id'],
        'code': json['code'],
        'technicalAttribute': TechnicalAttributeFromJSON(json['technicalAttribute']),
        'deleted': json['deleted'],
        'active': json['active'],
        'historical': json['historical'],
        'version': json['version'],
        'shmuId': !exists(json, 'shmuId') ? undefined : json['shmuId'],
    };
}

export function EnvironmentResultToJSON(value?: EnvironmentResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'internationalCode': value.internationalCode,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'internationalName': value.internationalName,
        'nameForReport': value.nameForReport,
        'nameForGis': value.nameForGis,
        'basinType': CodelistItemRequiredToJSON(value.basinType),
        'managerCode': value.managerCode,
        'hierarchicalLevel': value.hierarchicalLevel,
        'positionType': CodelistItemRequiredToJSON(value.positionType),
        'mapNumber': value.mapNumber,
        'length': value.length,
        'hydrologicalNumber': value.hydrologicalNumber,
        'vhpid': value.vhpid,
        'note': value.note,
        'environmentType': CodelistItemRequiredToJSON(value.environmentType),
        'environmentFormType': CodelistItemRequiredToJSON(value.environmentFormType),
        'level': LevelToJSON(value.level),
        'id': value.id,
        'code': value.code,
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
        'deleted': value.deleted,
        'active': value.active,
        'historical': value.historical,
        'version': value.version,
        'shmuId': value.shmuId,
    };
}

