/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CodelistExportDataSo,
  CodelistItemExportData,
  CodelistItemTemplateResponse,
  EndpointExceptionBody,
  ExportProcessInfoSo,
} from '../models/index';
import {
    CodelistExportDataSoFromJSON,
    CodelistExportDataSoToJSON,
    CodelistItemExportDataFromJSON,
    CodelistItemExportDataToJSON,
    CodelistItemTemplateResponseFromJSON,
    CodelistItemTemplateResponseToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    ExportProcessInfoSoFromJSON,
    ExportProcessInfoSoToJSON,
} from '../models/index';

export interface ExportApiGenerateCodelistItemImportTemplateRequest {
    codelistCode: number;
}

export interface ExportApiGenerateHeaderFileRequest {
    body: string;
}

export interface ExportApiGetStreamedFileRequest {
    uuid: string;
}

export interface ExportApiRefreshExportProcessInfoRequest {
    uuid: string;
}

export interface ExportApiStreamToCodelistExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    codelistExportDataSo: CodelistExportDataSo;
}

export interface ExportApiStreamToCodelistItemExportByCriteriaRequest {
    exportType: string;
    xlsxAutosize: boolean;
    codelistItemExportData: CodelistItemExportData;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     */
    async generateCodelistItemImportTemplateRaw(requestParameters: ExportApiGenerateCodelistItemImportTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItemTemplateResponse>> {
        if (requestParameters.codelistCode === null || requestParameters.codelistCode === undefined) {
            throw new runtime.RequiredError('codelistCode','Required parameter requestParameters.codelistCode was null or undefined when calling generateCodelistItemImportTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.codelistCode !== undefined) {
            queryParameters['codelistCode'] = requestParameters.codelistCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/codelist-item-import-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemTemplateResponseFromJSON(jsonValue));
    }

    /**
     */
    async generateCodelistItemImportTemplate(codelistCode: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItemTemplateResponse> {
        const response = await this.generateCodelistItemImportTemplateRaw({ codelistCode: codelistCode }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateHeaderFileRaw(requestParameters: ExportApiGenerateHeaderFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling generateHeaderFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/generate-header-file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async generateHeaderFile(body: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.generateHeaderFileRaw({ body: body }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStreamedFileRaw(requestParameters: ExportApiGetStreamedFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getStreamedFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/streamed-file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getStreamedFile(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getStreamedFileRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshExportProcessInfoRaw(requestParameters: ExportApiRefreshExportProcessInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportProcessInfoSo>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling refreshExportProcessInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/refresh-export-process-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportProcessInfoSoFromJSON(jsonValue));
    }

    /**
     */
    async refreshExportProcessInfo(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportProcessInfoSo> {
        const response = await this.refreshExportProcessInfoRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToCodelistExportByCriteriaRaw(requestParameters: ExportApiStreamToCodelistExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToCodelistExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToCodelistExportByCriteria.');
        }

        if (requestParameters.codelistExportDataSo === null || requestParameters.codelistExportDataSo === undefined) {
            throw new runtime.RequiredError('codelistExportDataSo','Required parameter requestParameters.codelistExportDataSo was null or undefined when calling streamToCodelistExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-codelist-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistExportDataSoToJSON(requestParameters.codelistExportDataSo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToCodelistExportByCriteria(exportType: string, xlsxAutosize: boolean, codelistExportDataSo: CodelistExportDataSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToCodelistExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, codelistExportDataSo: codelistExportDataSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async streamToCodelistItemExportByCriteriaRaw(requestParameters: ExportApiStreamToCodelistItemExportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling streamToCodelistItemExportByCriteria.');
        }

        if (requestParameters.xlsxAutosize === null || requestParameters.xlsxAutosize === undefined) {
            throw new runtime.RequiredError('xlsxAutosize','Required parameter requestParameters.xlsxAutosize was null or undefined when calling streamToCodelistItemExportByCriteria.');
        }

        if (requestParameters.codelistItemExportData === null || requestParameters.codelistItemExportData === undefined) {
            throw new runtime.RequiredError('codelistItemExportData','Required parameter requestParameters.codelistItemExportData was null or undefined when calling streamToCodelistItemExportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        if (requestParameters.xlsxAutosize !== undefined) {
            queryParameters['xlsxAutosize'] = requestParameters.xlsxAutosize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream-to-codelist-item-export-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemExportDataToJSON(requestParameters.codelistItemExportData),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async streamToCodelistItemExportByCriteria(exportType: string, xlsxAutosize: boolean, codelistItemExportData: CodelistItemExportData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.streamToCodelistItemExportByCriteriaRaw({ exportType: exportType, xlsxAutosize: xlsxAutosize, codelistItemExportData: codelistItemExportData }, initOverrides);
        return await response.value();
    }

}
