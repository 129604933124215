/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportProcessInfo,
  PageValidationReportResponse,
  PageValidationReportRowResponse,
  ValidationReportCriteriaRequest,
  ValidationReportRowCriteriaRequest,
} from '../models/index';
import {
    ExportProcessInfoFromJSON,
    ExportProcessInfoToJSON,
    PageValidationReportResponseFromJSON,
    PageValidationReportResponseToJSON,
    PageValidationReportRowResponseFromJSON,
    PageValidationReportRowResponseToJSON,
    ValidationReportCriteriaRequestFromJSON,
    ValidationReportCriteriaRequestToJSON,
    ValidationReportRowCriteriaRequestFromJSON,
    ValidationReportRowCriteriaRequestToJSON,
} from '../models/index';

export interface ReportApiDownloadValidationReportRequest {
    uuid: string;
}

export interface ReportApiGenerateRowErrorsReportRequest {
    exportType: GenerateRowErrorsReportExportTypeEnum;
    validationReportRowCriteriaRequest: ValidationReportRowCriteriaRequest;
}

export interface ReportApiGetRowByCriteriaRequest {
    sortDirections: Array<string>;
    sortProperties: Array<string>;
    validationReportRowCriteriaRequest: ValidationReportRowCriteriaRequest;
    page?: number;
    pageSize?: number;
}

export interface ReportApiGetValidationReportRequest {
    documentId: string;
    sortDirections: Array<string>;
    sortProperties: Array<string>;
    dataSet?: number;
    page?: number;
    pageSize?: number;
}

export interface ReportApiGetValidationReportByCriteriaRequest {
    sortDirections: Array<string>;
    sortProperties: Array<string>;
    validationReportCriteriaRequest: ValidationReportCriteriaRequest;
    page?: number;
    pageSize?: number;
}

export interface ReportApiRefreshProcessInfoRequest {
    uuid: string;
}

export interface ReportApiStartDownloadValidationReportRequest {
    sortDirections: Array<string>;
    sortProperties: Array<string>;
    exportType: StartDownloadValidationReportExportTypeEnum;
    validationReportCriteriaRequest: ValidationReportCriteriaRequest;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     */
    async downloadValidationReportRaw(requestParameters: ReportApiDownloadValidationReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling downloadValidationReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/download-validation-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async downloadValidationReport(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadValidationReportRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     * Generate report with row validation and transformation errors in document by criteria
     */
    async generateRowErrorsReportRaw(requestParameters: ReportApiGenerateRowErrorsReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling generateRowErrorsReport.');
        }

        if (requestParameters.validationReportRowCriteriaRequest === null || requestParameters.validationReportRowCriteriaRequest === undefined) {
            throw new runtime.RequiredError('validationReportRowCriteriaRequest','Required parameter requestParameters.validationReportRowCriteriaRequest was null or undefined when calling generateRowErrorsReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/generate-row-errors-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidationReportRowCriteriaRequestToJSON(requestParameters.validationReportRowCriteriaRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generate report with row validation and transformation errors in document by criteria
     */
    async generateRowErrorsReport(exportType: GenerateRowErrorsReportExportTypeEnum, validationReportRowCriteriaRequest: ValidationReportRowCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateRowErrorsReportRaw({ exportType: exportType, validationReportRowCriteriaRequest: validationReportRowCriteriaRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRowByCriteriaRaw(requestParameters: ReportApiGetRowByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageValidationReportRowResponse>> {
        if (requestParameters.sortDirections === null || requestParameters.sortDirections === undefined) {
            throw new runtime.RequiredError('sortDirections','Required parameter requestParameters.sortDirections was null or undefined when calling getRowByCriteria.');
        }

        if (requestParameters.sortProperties === null || requestParameters.sortProperties === undefined) {
            throw new runtime.RequiredError('sortProperties','Required parameter requestParameters.sortProperties was null or undefined when calling getRowByCriteria.');
        }

        if (requestParameters.validationReportRowCriteriaRequest === null || requestParameters.validationReportRowCriteriaRequest === undefined) {
            throw new runtime.RequiredError('validationReportRowCriteriaRequest','Required parameter requestParameters.validationReportRowCriteriaRequest was null or undefined when calling getRowByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirections) {
            queryParameters['sortDirections'] = requestParameters.sortDirections;
        }

        if (requestParameters.sortProperties) {
            queryParameters['sortProperties'] = requestParameters.sortProperties;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/row-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidationReportRowCriteriaRequestToJSON(requestParameters.validationReportRowCriteriaRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageValidationReportRowResponseFromJSON(jsonValue));
    }

    /**
     */
    async getRowByCriteria(sortDirections: Array<string>, sortProperties: Array<string>, validationReportRowCriteriaRequest: ValidationReportRowCriteriaRequest, page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageValidationReportRowResponse> {
        const response = await this.getRowByCriteriaRaw({ sortDirections: sortDirections, sortProperties: sortProperties, validationReportRowCriteriaRequest: validationReportRowCriteriaRequest, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     * Get validation and transformation error report for document
     */
    async getValidationReportRaw(requestParameters: ReportApiGetValidationReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageValidationReportResponse>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getValidationReport.');
        }

        if (requestParameters.sortDirections === null || requestParameters.sortDirections === undefined) {
            throw new runtime.RequiredError('sortDirections','Required parameter requestParameters.sortDirections was null or undefined when calling getValidationReport.');
        }

        if (requestParameters.sortProperties === null || requestParameters.sortProperties === undefined) {
            throw new runtime.RequiredError('sortProperties','Required parameter requestParameters.sortProperties was null or undefined when calling getValidationReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        if (requestParameters.dataSet !== undefined) {
            queryParameters['dataSet'] = requestParameters.dataSet;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirections) {
            queryParameters['sortDirections'] = requestParameters.sortDirections;
        }

        if (requestParameters.sortProperties) {
            queryParameters['sortProperties'] = requestParameters.sortProperties;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/validation-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageValidationReportResponseFromJSON(jsonValue));
    }

    /**
     * Get validation and transformation error report for document
     */
    async getValidationReport(documentId: string, sortDirections: Array<string>, sortProperties: Array<string>, dataSet?: number, page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageValidationReportResponse> {
        const response = await this.getValidationReportRaw({ documentId: documentId, sortDirections: sortDirections, sortProperties: sortProperties, dataSet: dataSet, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     * Get validation and transformation error report for document by criteria
     */
    async getValidationReportByCriteriaRaw(requestParameters: ReportApiGetValidationReportByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageValidationReportResponse>> {
        if (requestParameters.sortDirections === null || requestParameters.sortDirections === undefined) {
            throw new runtime.RequiredError('sortDirections','Required parameter requestParameters.sortDirections was null or undefined when calling getValidationReportByCriteria.');
        }

        if (requestParameters.sortProperties === null || requestParameters.sortProperties === undefined) {
            throw new runtime.RequiredError('sortProperties','Required parameter requestParameters.sortProperties was null or undefined when calling getValidationReportByCriteria.');
        }

        if (requestParameters.validationReportCriteriaRequest === null || requestParameters.validationReportCriteriaRequest === undefined) {
            throw new runtime.RequiredError('validationReportCriteriaRequest','Required parameter requestParameters.validationReportCriteriaRequest was null or undefined when calling getValidationReportByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.sortDirections) {
            queryParameters['sortDirections'] = requestParameters.sortDirections;
        }

        if (requestParameters.sortProperties) {
            queryParameters['sortProperties'] = requestParameters.sortProperties;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidationReportCriteriaRequestToJSON(requestParameters.validationReportCriteriaRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageValidationReportResponseFromJSON(jsonValue));
    }

    /**
     * Get validation and transformation error report for document by criteria
     */
    async getValidationReportByCriteria(sortDirections: Array<string>, sortProperties: Array<string>, validationReportCriteriaRequest: ValidationReportCriteriaRequest, page?: number, pageSize?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageValidationReportResponse> {
        const response = await this.getValidationReportByCriteriaRaw({ sortDirections: sortDirections, sortProperties: sortProperties, validationReportCriteriaRequest: validationReportCriteriaRequest, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshProcessInfoRaw(requestParameters: ReportApiRefreshProcessInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportProcessInfo>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling refreshProcessInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/refresh-process-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportProcessInfoFromJSON(jsonValue));
    }

    /**
     */
    async refreshProcessInfo(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportProcessInfo> {
        const response = await this.refreshProcessInfoRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     */
    async startDownloadValidationReportRaw(requestParameters: ReportApiStartDownloadValidationReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sortDirections === null || requestParameters.sortDirections === undefined) {
            throw new runtime.RequiredError('sortDirections','Required parameter requestParameters.sortDirections was null or undefined when calling startDownloadValidationReport.');
        }

        if (requestParameters.sortProperties === null || requestParameters.sortProperties === undefined) {
            throw new runtime.RequiredError('sortProperties','Required parameter requestParameters.sortProperties was null or undefined when calling startDownloadValidationReport.');
        }

        if (requestParameters.exportType === null || requestParameters.exportType === undefined) {
            throw new runtime.RequiredError('exportType','Required parameter requestParameters.exportType was null or undefined when calling startDownloadValidationReport.');
        }

        if (requestParameters.validationReportCriteriaRequest === null || requestParameters.validationReportCriteriaRequest === undefined) {
            throw new runtime.RequiredError('validationReportCriteriaRequest','Required parameter requestParameters.validationReportCriteriaRequest was null or undefined when calling startDownloadValidationReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortDirections) {
            queryParameters['sortDirections'] = requestParameters.sortDirections;
        }

        if (requestParameters.sortProperties) {
            queryParameters['sortProperties'] = requestParameters.sortProperties;
        }

        if (requestParameters.exportType !== undefined) {
            queryParameters['exportType'] = requestParameters.exportType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/start-download-validation-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidationReportCriteriaRequestToJSON(requestParameters.validationReportCriteriaRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async startDownloadValidationReport(sortDirections: Array<string>, sortProperties: Array<string>, exportType: StartDownloadValidationReportExportTypeEnum, validationReportCriteriaRequest: ValidationReportCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.startDownloadValidationReportRaw({ sortDirections: sortDirections, sortProperties: sortProperties, exportType: exportType, validationReportCriteriaRequest: validationReportCriteriaRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GenerateRowErrorsReportExportTypeEnum = {
    Csv: 'CSV',
    Xlsx: 'XLSX'
} as const;
export type GenerateRowErrorsReportExportTypeEnum = typeof GenerateRowErrorsReportExportTypeEnum[keyof typeof GenerateRowErrorsReportExportTypeEnum];
/**
 * @export
 */
export const StartDownloadValidationReportExportTypeEnum = {
    Csv: 'CSV',
    Xlsx: 'XLSX'
} as const;
export type StartDownloadValidationReportExportTypeEnum = typeof StartDownloadValidationReportExportTypeEnum[keyof typeof StartDownloadValidationReportExportTypeEnum];
