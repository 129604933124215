/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransformCodes
 */
export interface TransformCodes {
    /**
     * 
     * @type {number}
     * @memberof TransformCodes
     */
    methodic?: number;
    /**
     * 
     * @type {string}
     * @memberof TransformCodes
     */
    methodCodeInt?: string;
    /**
     * 
     * @type {number}
     * @memberof TransformCodes
     */
    methodGroup?: number;
    /**
     * 
     * @type {string}
     * @memberof TransformCodes
     */
    detCodeInt?: string;
    /**
     * 
     * @type {number}
     * @memberof TransformCodes
     */
    determinant?: number;
    /**
     * 
     * @type {number}
     * @memberof TransformCodes
     */
    method?: number;
}

/**
 * Check if a given object implements the TransformCodes interface.
 */
export function instanceOfTransformCodes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TransformCodesFromJSON(json: any): TransformCodes {
    return TransformCodesFromJSONTyped(json, false);
}

export function TransformCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransformCodes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'methodic': !exists(json, 'methodic') ? undefined : json['methodic'],
        'methodCodeInt': !exists(json, 'methodCodeInt') ? undefined : json['methodCodeInt'],
        'methodGroup': !exists(json, 'methodGroup') ? undefined : json['methodGroup'],
        'detCodeInt': !exists(json, 'detCodeInt') ? undefined : json['detCodeInt'],
        'determinant': !exists(json, 'determinant') ? undefined : json['determinant'],
        'method': !exists(json, 'method') ? undefined : json['method'],
    };
}

export function TransformCodesToJSON(value?: TransformCodes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'methodic': value.methodic,
        'methodCodeInt': value.methodCodeInt,
        'methodGroup': value.methodGroup,
        'detCodeInt': value.detCodeInt,
        'determinant': value.determinant,
        'method': value.method,
    };
}

