/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MethodicCriteria
 */
export interface MethodicCriteria {
    /**
     * 
     * @type {number}
     * @memberof MethodicCriteria
     */
    institutionType?: number;
    /**
     * 
     * @type {number}
     * @memberof MethodicCriteria
     */
    determinantType?: number;
    /**
     * 
     * @type {number}
     * @memberof MethodicCriteria
     */
    anMethodType?: number;
    /**
     * 
     * @type {number}
     * @memberof MethodicCriteria
     */
    anMethodGroupType?: number;
    /**
     * 
     * @type {string}
     * @memberof MethodicCriteria
     */
    intDetCode?: string;
}

/**
 * Check if a given object implements the MethodicCriteria interface.
 */
export function instanceOfMethodicCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MethodicCriteriaFromJSON(json: any): MethodicCriteria {
    return MethodicCriteriaFromJSONTyped(json, false);
}

export function MethodicCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MethodicCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'institutionType': !exists(json, 'institutionType') ? undefined : json['institutionType'],
        'determinantType': !exists(json, 'determinantType') ? undefined : json['determinantType'],
        'anMethodType': !exists(json, 'anMethodType') ? undefined : json['anMethodType'],
        'anMethodGroupType': !exists(json, 'anMethodGroupType') ? undefined : json['anMethodGroupType'],
        'intDetCode': !exists(json, 'intDetCode') ? undefined : json['intDetCode'],
    };
}

export function MethodicCriteriaToJSON(value?: MethodicCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'institutionType': value.institutionType,
        'determinantType': value.determinantType,
        'anMethodType': value.anMethodType,
        'anMethodGroupType': value.anMethodGroupType,
        'intDetCode': value.intDetCode,
    };
}

