/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  ItemCriteria,
  Methodic,
  MethodicCriteria,
  MethodicResult,
  PageCodelistForMethodic,
  PageDeterminantCodeInternationalItem,
  PageMethodicResult,
  TransformCodes,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    ItemCriteriaFromJSON,
    ItemCriteriaToJSON,
    MethodicFromJSON,
    MethodicToJSON,
    MethodicCriteriaFromJSON,
    MethodicCriteriaToJSON,
    MethodicResultFromJSON,
    MethodicResultToJSON,
    PageCodelistForMethodicFromJSON,
    PageCodelistForMethodicToJSON,
    PageDeterminantCodeInternationalItemFromJSON,
    PageDeterminantCodeInternationalItemToJSON,
    PageMethodicResultFromJSON,
    PageMethodicResultToJSON,
    TransformCodesFromJSON,
    TransformCodesToJSON,
} from '../models/index';

export interface MethodicApiCreateMethodicRequest {
    methodic: Methodic;
}

export interface MethodicApiFindAnMethodByCriteriaRequest {
    itemCriteria: ItemCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MethodicApiFindAnMethodGroupByCriteriaRequest {
    itemCriteria: ItemCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MethodicApiFindDeterminantByCriteriaRequest {
    itemCriteria: ItemCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MethodicApiFindDeterminantCodeInternationalByCriteriaRequest {
    itemCriteria: ItemCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MethodicApiFindInstitutionByCriteriaRequest {
    itemCriteria: ItemCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MethodicApiFindMethodicBySearchCriteriaRequest {
    methodicCriteria: MethodicCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MethodicApiGetDeleteRequest {
    id: number;
}

export interface MethodicApiGetMethodicRequest {
    id: number;
}

export interface MethodicApiTransformCodesRequest {
    methodCodeInternational: string;
    determinantCodeInternational: string;
    dateTime: Array<string>;
}

export interface MethodicApiUpdateMethodicRequest {
    id: number;
    methodic: Methodic;
}

/**
 * 
 */
export class MethodicApi extends runtime.BaseAPI {

    /**
     */
    async createMethodicRaw(requestParameters: MethodicApiCreateMethodicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MethodicResult>> {
        if (requestParameters.methodic === null || requestParameters.methodic === undefined) {
            throw new runtime.RequiredError('methodic','Required parameter requestParameters.methodic was null or undefined when calling createMethodic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MethodicToJSON(requestParameters.methodic),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MethodicResultFromJSON(jsonValue));
    }

    /**
     */
    async createMethodic(methodic: Methodic, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MethodicResult> {
        const response = await this.createMethodicRaw({ methodic: methodic }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAnMethodByCriteriaRaw(requestParameters: MethodicApiFindAnMethodByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistForMethodic>> {
        if (requestParameters.itemCriteria === null || requestParameters.itemCriteria === undefined) {
            throw new runtime.RequiredError('itemCriteria','Required parameter requestParameters.itemCriteria was null or undefined when calling findAnMethodByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/an-method-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemCriteriaToJSON(requestParameters.itemCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistForMethodicFromJSON(jsonValue));
    }

    /**
     */
    async findAnMethodByCriteria(itemCriteria: ItemCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistForMethodic> {
        const response = await this.findAnMethodByCriteriaRaw({ itemCriteria: itemCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAnMethodGroupByCriteriaRaw(requestParameters: MethodicApiFindAnMethodGroupByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistForMethodic>> {
        if (requestParameters.itemCriteria === null || requestParameters.itemCriteria === undefined) {
            throw new runtime.RequiredError('itemCriteria','Required parameter requestParameters.itemCriteria was null or undefined when calling findAnMethodGroupByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/an-method-group-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemCriteriaToJSON(requestParameters.itemCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistForMethodicFromJSON(jsonValue));
    }

    /**
     */
    async findAnMethodGroupByCriteria(itemCriteria: ItemCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistForMethodic> {
        const response = await this.findAnMethodGroupByCriteriaRaw({ itemCriteria: itemCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDeterminantByCriteriaRaw(requestParameters: MethodicApiFindDeterminantByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistForMethodic>> {
        if (requestParameters.itemCriteria === null || requestParameters.itemCriteria === undefined) {
            throw new runtime.RequiredError('itemCriteria','Required parameter requestParameters.itemCriteria was null or undefined when calling findDeterminantByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/determinant-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemCriteriaToJSON(requestParameters.itemCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistForMethodicFromJSON(jsonValue));
    }

    /**
     */
    async findDeterminantByCriteria(itemCriteria: ItemCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistForMethodic> {
        const response = await this.findDeterminantByCriteriaRaw({ itemCriteria: itemCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDeterminantCodeInternationalByCriteriaRaw(requestParameters: MethodicApiFindDeterminantCodeInternationalByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDeterminantCodeInternationalItem>> {
        if (requestParameters.itemCriteria === null || requestParameters.itemCriteria === undefined) {
            throw new runtime.RequiredError('itemCriteria','Required parameter requestParameters.itemCriteria was null or undefined when calling findDeterminantCodeInternationalByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/determinant-code-international-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemCriteriaToJSON(requestParameters.itemCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDeterminantCodeInternationalItemFromJSON(jsonValue));
    }

    /**
     */
    async findDeterminantCodeInternationalByCriteria(itemCriteria: ItemCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDeterminantCodeInternationalItem> {
        const response = await this.findDeterminantCodeInternationalByCriteriaRaw({ itemCriteria: itemCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findInstitutionByCriteriaRaw(requestParameters: MethodicApiFindInstitutionByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistForMethodic>> {
        if (requestParameters.itemCriteria === null || requestParameters.itemCriteria === undefined) {
            throw new runtime.RequiredError('itemCriteria','Required parameter requestParameters.itemCriteria was null or undefined when calling findInstitutionByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/institution-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemCriteriaToJSON(requestParameters.itemCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistForMethodicFromJSON(jsonValue));
    }

    /**
     */
    async findInstitutionByCriteria(itemCriteria: ItemCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistForMethodic> {
        const response = await this.findInstitutionByCriteriaRaw({ itemCriteria: itemCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findMethodicBySearchCriteriaRaw(requestParameters: MethodicApiFindMethodicBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageMethodicResult>> {
        if (requestParameters.methodicCriteria === null || requestParameters.methodicCriteria === undefined) {
            throw new runtime.RequiredError('methodicCriteria','Required parameter requestParameters.methodicCriteria was null or undefined when calling findMethodicBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MethodicCriteriaToJSON(requestParameters.methodicCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageMethodicResultFromJSON(jsonValue));
    }

    /**
     */
    async findMethodicBySearchCriteria(methodicCriteria: MethodicCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageMethodicResult> {
        const response = await this.findMethodicBySearchCriteriaRaw({ methodicCriteria: methodicCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDeleteRaw(requestParameters: MethodicApiGetDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getDelete(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async getMethodicRaw(requestParameters: MethodicApiGetMethodicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MethodicResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMethodic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MethodicResultFromJSON(jsonValue));
    }

    /**
     */
    async getMethodic(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MethodicResult> {
        const response = await this.getMethodicRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async synchronizeMethodicRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/synchronize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async synchronizeMethodic(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.synchronizeMethodicRaw(initOverrides);
    }

    /**
     */
    async transformCodesRaw(requestParameters: MethodicApiTransformCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TransformCodes>> {
        if (requestParameters.methodCodeInternational === null || requestParameters.methodCodeInternational === undefined) {
            throw new runtime.RequiredError('methodCodeInternational','Required parameter requestParameters.methodCodeInternational was null or undefined when calling transformCodes.');
        }

        if (requestParameters.determinantCodeInternational === null || requestParameters.determinantCodeInternational === undefined) {
            throw new runtime.RequiredError('determinantCodeInternational','Required parameter requestParameters.determinantCodeInternational was null or undefined when calling transformCodes.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling transformCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/transform-codes/{methodCodeInternational}/{determinantCodeInternational}/{dateTime}`.replace(`{${"methodCodeInternational"}}`, encodeURIComponent(String(requestParameters.methodCodeInternational))).replace(`{${"determinantCodeInternational"}}`, encodeURIComponent(String(requestParameters.determinantCodeInternational))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TransformCodesFromJSON(jsonValue));
    }

    /**
     */
    async transformCodes(methodCodeInternational: string, determinantCodeInternational: string, dateTime: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TransformCodes> {
        const response = await this.transformCodesRaw({ methodCodeInternational: methodCodeInternational, determinantCodeInternational: determinantCodeInternational, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMethodicRaw(requestParameters: MethodicApiUpdateMethodicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MethodicResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMethodic.');
        }

        if (requestParameters.methodic === null || requestParameters.methodic === undefined) {
            throw new runtime.RequiredError('methodic','Required parameter requestParameters.methodic was null or undefined when calling updateMethodic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/methodic/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MethodicToJSON(requestParameters.methodic),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MethodicResultFromJSON(jsonValue));
    }

    /**
     */
    async updateMethodic(id: number, methodic: Methodic, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MethodicResult> {
        const response = await this.updateMethodicRaw({ id: id, methodic: methodic }, initOverrides);
        return await response.value();
    }

}
