/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CodelistItemCriteria,
  EndpointExceptionBody,
  PageUserGroupSearchResult,
  UserGroupCriteria,
  UserGroupInput,
  UserGroupResult,
  UserGroupUpdate,
} from '../models/index';
import {
    CodelistItemCriteriaFromJSON,
    CodelistItemCriteriaToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    PageUserGroupSearchResultFromJSON,
    PageUserGroupSearchResultToJSON,
    UserGroupCriteriaFromJSON,
    UserGroupCriteriaToJSON,
    UserGroupInputFromJSON,
    UserGroupInputToJSON,
    UserGroupResultFromJSON,
    UserGroupResultToJSON,
    UserGroupUpdateFromJSON,
    UserGroupUpdateToJSON,
} from '../models/index';

export interface UserGroupApiCreateUserGroupRequest {
    userGroupInput: UserGroupInput;
}

export interface UserGroupApiDeleteUserGroupByIdRequest {
    id: number;
}

export interface UserGroupApiFindUserGroupByCriteriaRequest {
    userGroupCriteria: UserGroupCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UserGroupApiGetCountOfCodelistInUserGroupByUserGroupIdRequest {
    userGroupId: number;
}

export interface UserGroupApiGetUserGroupByIdRequest {
    id: number;
}

export interface UserGroupApiSaveCodelistInUserGroupRequest {
    userGroupId: number;
    codelistItemCriteria: CodelistItemCriteria;
}

export interface UserGroupApiUpdateUserGroupByIdRequest {
    id: number;
    userGroupUpdate: UserGroupUpdate;
}

/**
 * 
 */
export class UserGroupApi extends runtime.BaseAPI {

    /**
     */
    async createUserGroupRaw(requestParameters: UserGroupApiCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupResult>> {
        if (requestParameters.userGroupInput === null || requestParameters.userGroupInput === undefined) {
            throw new runtime.RequiredError('userGroupInput','Required parameter requestParameters.userGroupInput was null or undefined when calling createUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupInputToJSON(requestParameters.userGroupInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResultFromJSON(jsonValue));
    }

    /**
     */
    async createUserGroup(userGroupInput: UserGroupInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupResult> {
        const response = await this.createUserGroupRaw({ userGroupInput: userGroupInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserGroupByIdRaw(requestParameters: UserGroupApiDeleteUserGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserGroupById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserGroupByIdRaw({ id: id }, initOverrides);
    }

    /**
     */
    async findUserGroupByCriteriaRaw(requestParameters: UserGroupApiFindUserGroupByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageUserGroupSearchResult>> {
        if (requestParameters.userGroupCriteria === null || requestParameters.userGroupCriteria === undefined) {
            throw new runtime.RequiredError('userGroupCriteria','Required parameter requestParameters.userGroupCriteria was null or undefined when calling findUserGroupByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupCriteriaToJSON(requestParameters.userGroupCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageUserGroupSearchResultFromJSON(jsonValue));
    }

    /**
     */
    async findUserGroupByCriteria(userGroupCriteria: UserGroupCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageUserGroupSearchResult> {
        const response = await this.findUserGroupByCriteriaRaw({ userGroupCriteria: userGroupCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCountOfCodelistInUserGroupByUserGroupIdRaw(requestParameters: UserGroupApiGetCountOfCodelistInUserGroupByUserGroupIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling getCountOfCodelistInUserGroupByUserGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/count/{userGroupId}`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getCountOfCodelistInUserGroupByUserGroupId(userGroupId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountOfCodelistInUserGroupByUserGroupIdRaw({ userGroupId: userGroupId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserGroupByIdRaw(requestParameters: UserGroupApiGetUserGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResultFromJSON(jsonValue));
    }

    /**
     */
    async getUserGroupById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupResult> {
        const response = await this.getUserGroupByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveCodelistInUserGroupRaw(requestParameters: UserGroupApiSaveCodelistInUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling saveCodelistInUserGroup.');
        }

        if (requestParameters.codelistItemCriteria === null || requestParameters.codelistItemCriteria === undefined) {
            throw new runtime.RequiredError('codelistItemCriteria','Required parameter requestParameters.codelistItemCriteria was null or undefined when calling saveCodelistInUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/save-codelist-in-user-group/{userGroupId}`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemCriteriaToJSON(requestParameters.codelistItemCriteria),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveCodelistInUserGroup(userGroupId: number, codelistItemCriteria: CodelistItemCriteria, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveCodelistInUserGroupRaw({ userGroupId: userGroupId, codelistItemCriteria: codelistItemCriteria }, initOverrides);
    }

    /**
     */
    async updateUserGroupByIdRaw(requestParameters: UserGroupApiUpdateUserGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserGroupById.');
        }

        if (requestParameters.userGroupUpdate === null || requestParameters.userGroupUpdate === undefined) {
            throw new runtime.RequiredError('userGroupUpdate','Required parameter requestParameters.userGroupUpdate was null or undefined when calling updateUserGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupUpdateToJSON(requestParameters.userGroupUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResultFromJSON(jsonValue));
    }

    /**
     */
    async updateUserGroupById(id: number, userGroupUpdate: UserGroupUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupResult> {
        const response = await this.updateUserGroupByIdRaw({ id: id, userGroupUpdate: userGroupUpdate }, initOverrides);
        return await response.value();
    }

}
