/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvironmentCriteria
 */
export interface EnvironmentCriteria {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    internationalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    internationalName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    nameForReport?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    nameForGis?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    managerCode?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentCriteria
     */
    hierarchicalLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentCriteria
     */
    positionType?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    mapNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentCriteria
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    hydrologicalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    vhpid?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentCriteria
     */
    active?: EnvironmentCriteriaActiveEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentCriteria
     */
    deleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentCriteria
     */
    basinType?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentCriteria
     */
    environmentType?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentCriteria
     */
    environmentFormType?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EnvironmentCriteria
     */
    ids?: Array<number>;
}


/**
 * @export
 */
export const EnvironmentCriteriaActiveEnum = {
    True: 'TRUE',
    False: 'FALSE'
} as const;
export type EnvironmentCriteriaActiveEnum = typeof EnvironmentCriteriaActiveEnum[keyof typeof EnvironmentCriteriaActiveEnum];


/**
 * Check if a given object implements the EnvironmentCriteria interface.
 */
export function instanceOfEnvironmentCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EnvironmentCriteriaFromJSON(json: any): EnvironmentCriteria {
    return EnvironmentCriteriaFromJSONTyped(json, false);
}

export function EnvironmentCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'internationalCode': !exists(json, 'internationalCode') ? undefined : json['internationalCode'],
        'internationalName': !exists(json, 'internationalName') ? undefined : json['internationalName'],
        'nameForReport': !exists(json, 'nameForReport') ? undefined : json['nameForReport'],
        'nameForGis': !exists(json, 'nameForGis') ? undefined : json['nameForGis'],
        'managerCode': !exists(json, 'managerCode') ? undefined : json['managerCode'],
        'hierarchicalLevel': !exists(json, 'hierarchicalLevel') ? undefined : json['hierarchicalLevel'],
        'positionType': !exists(json, 'positionType') ? undefined : json['positionType'],
        'mapNumber': !exists(json, 'mapNumber') ? undefined : json['mapNumber'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'hydrologicalNumber': !exists(json, 'hydrologicalNumber') ? undefined : json['hydrologicalNumber'],
        'vhpid': !exists(json, 'vhpid') ? undefined : json['vhpid'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'basinType': !exists(json, 'basinType') ? undefined : json['basinType'],
        'environmentType': !exists(json, 'environmentType') ? undefined : json['environmentType'],
        'environmentFormType': !exists(json, 'environmentFormType') ? undefined : json['environmentFormType'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
    };
}

export function EnvironmentCriteriaToJSON(value?: EnvironmentCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'name': value.name,
        'code': value.code,
        'internationalCode': value.internationalCode,
        'internationalName': value.internationalName,
        'nameForReport': value.nameForReport,
        'nameForGis': value.nameForGis,
        'managerCode': value.managerCode,
        'hierarchicalLevel': value.hierarchicalLevel,
        'positionType': value.positionType,
        'mapNumber': value.mapNumber,
        'length': value.length,
        'hydrologicalNumber': value.hydrologicalNumber,
        'vhpid': value.vhpid,
        'note': value.note,
        'active': value.active,
        'deleted': value.deleted,
        'basinType': value.basinType,
        'environmentType': value.environmentType,
        'environmentFormType': value.environmentFormType,
        'ids': value.ids,
    };
}

