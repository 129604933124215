// Enumy
export enum SeparatorEnum {
    Semicolon = ';',
    Comma = ',',
    Space = ' ',
    Pipe = '|'
}
export enum FileTypeEnum {
    Csv = 'CSV',
    Xls = 'XLS',
    Xlsx = 'XLSX'
}
export enum EncodingEnum {
    Latin1 = 'ISO-8859-1',
    Utf8 = 'UTF-8',
    Windows1250 = 'windows-1250'
}
export enum FixedCodeitemAttributesEnum {
    Code = 'code',
    Acronym = 'acronym',
    ShortenedName = 'shortenedName',
    AbbreviatedName = 'abbreviatedName'
}

export enum ValidationTypeEnum {
    Basic = 'BASIC',
    LogOp = 'LOG_OP',
    OrOp = 'OR_OP',
    IfThen = 'IF_THEN'
}

export enum ConversionTypeEnum {
    Conversion = 'CONVERSION'
}

export enum ValueTypeEnum {
    Determinant = 'DETERMINANT',
    Constant = 'CONSTANT'
}

export enum OperatorTypeEnum {
    Arithmetic = 'ARITHMETIC',
    Comparison = 'COMPARISON',
    Logical = 'LOGICAL'
}

export enum LogicalOperatorTypeEnum {
    And = 'AND',
    Or = 'OR'
}

export enum ComparisonOperatorTypeEnum {
    GreaterThan = 'GRATER_THAN',
    LessThan = 'LESS_THAN',
    GreaterThanOrEqual = 'GRATER_THAN_OR_EQUAL',
    LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
    Equal = 'EQUAL'
}

export enum ArithmeticOperatorTypeEnum {
    Addition = 'ADDITION',
    Subtraction = 'SUBTRACTION',
    Multiplication = 'MULTIPLICATION',
    Division = 'DIVISION'
}
