/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanResponse,
  Codelist,
  CodelistCreate,
  CodelistCriteria,
  CodelistInput,
  Csv,
  DeleteCodelistsInput,
  DeleteCodelistsOutput,
  EndpointExceptionBody,
  PageCodelist,
} from '../models/index';
import {
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    CodelistFromJSON,
    CodelistToJSON,
    CodelistCreateFromJSON,
    CodelistCreateToJSON,
    CodelistCriteriaFromJSON,
    CodelistCriteriaToJSON,
    CodelistInputFromJSON,
    CodelistInputToJSON,
    CsvFromJSON,
    CsvToJSON,
    DeleteCodelistsInputFromJSON,
    DeleteCodelistsInputToJSON,
    DeleteCodelistsOutputFromJSON,
    DeleteCodelistsOutputToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    PageCodelistFromJSON,
    PageCodelistToJSON,
} from '../models/index';

export interface CodelistApiAcronymExistsRequest {
    acronym: string;
    code?: number;
}

export interface CodelistApiChangeAttributeKeyRequest {
    code: number;
    oldKeyValue: string;
    newKeyValue: string;
}

export interface CodelistApiCodeExistsRequest {
    code: number;
}

export interface CodelistApiCreateRequest {
    codelistCreate: CodelistCreate;
}

export interface CodelistApiDeleteAttributeRequest {
    code: number;
    key: string;
}

export interface CodelistApiDeleteCodelistRequest {
    code: number;
}

export interface CodelistApiDeleteCodelistsRequest {
    deleteCodelistsInput: DeleteCodelistsInput;
}

export interface CodelistApiGetByCodeRequest {
    code: number;
}

export interface CodelistApiGetByCriteriaRequest {
    codelistCriteria: CodelistCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface CodelistApiGetChangesRequest {
    dateFrom: string;
    page?: number;
    size?: number;
    dateTo?: string;
}

export interface CodelistApiGetCodelistsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface CodelistApiGetLastGenerateItemCodeRequest {
    code: number;
}

export interface CodelistApiMissingAttributesCheckRequest {
    uuid: string;
}

export interface CodelistApiMissingAttributesCheck1Request {
    code?: number;
}

export interface CodelistApiMissingAttributesGetRequest {
    uuid: string;
}

export interface CodelistApiUpdateRequest {
    code: number;
    codelistInput: CodelistInput;
}

/**
 * 
 */
export class CodelistApi extends runtime.BaseAPI {

    /**
     * Checks the uniqueness of the acronym.
     */
    async acronymExistsRaw(requestParameters: CodelistApiAcronymExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.acronym === null || requestParameters.acronym === undefined) {
            throw new runtime.RequiredError('acronym','Required parameter requestParameters.acronym was null or undefined when calling acronymExists.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.acronym !== undefined) {
            queryParameters['acronym'] = requestParameters.acronym;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/acronym-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     * Checks the uniqueness of the acronym.
     */
    async acronymExists(acronym: string, code?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.acronymExistsRaw({ acronym: acronym, code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Change the attribute key and update keys on all items.
     */
    async changeAttributeKeyRaw(requestParameters: CodelistApiChangeAttributeKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Codelist>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling changeAttributeKey.');
        }

        if (requestParameters.oldKeyValue === null || requestParameters.oldKeyValue === undefined) {
            throw new runtime.RequiredError('oldKeyValue','Required parameter requestParameters.oldKeyValue was null or undefined when calling changeAttributeKey.');
        }

        if (requestParameters.newKeyValue === null || requestParameters.newKeyValue === undefined) {
            throw new runtime.RequiredError('newKeyValue','Required parameter requestParameters.newKeyValue was null or undefined when calling changeAttributeKey.');
        }

        const queryParameters: any = {};

        if (requestParameters.oldKeyValue !== undefined) {
            queryParameters['oldKeyValue'] = requestParameters.oldKeyValue;
        }

        if (requestParameters.newKeyValue !== undefined) {
            queryParameters['newKeyValue'] = requestParameters.newKeyValue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/change-attribute/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistFromJSON(jsonValue));
    }

    /**
     * Change the attribute key and update keys on all items.
     */
    async changeAttributeKey(code: number, oldKeyValue: string, newKeyValue: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Codelist> {
        const response = await this.changeAttributeKeyRaw({ code: code, oldKeyValue: oldKeyValue, newKeyValue: newKeyValue }, initOverrides);
        return await response.value();
    }

    /**
     * Checks the uniqueness of the code.
     */
    async codeExistsRaw(requestParameters: CodelistApiCodeExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling codeExists.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/code-exists/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     * Checks the uniqueness of the code.
     */
    async codeExists(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.codeExistsRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Create the codelist.
     */
    async createRaw(requestParameters: CodelistApiCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Codelist>> {
        if (requestParameters.codelistCreate === null || requestParameters.codelistCreate === undefined) {
            throw new runtime.RequiredError('codelistCreate','Required parameter requestParameters.codelistCreate was null or undefined when calling create.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistCreateToJSON(requestParameters.codelistCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistFromJSON(jsonValue));
    }

    /**
     * Create the codelist.
     */
    async create(codelistCreate: CodelistCreate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Codelist> {
        const response = await this.createRaw({ codelistCreate: codelistCreate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAttributeRaw(requestParameters: CodelistApiDeleteAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Codelist>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling deleteAttribute.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteAttribute.');
        }

        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delete-attribute/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistFromJSON(jsonValue));
    }

    /**
     */
    async deleteAttribute(code: number, key: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Codelist> {
        const response = await this.deleteAttributeRaw({ code: code, key: key }, initOverrides);
        return await response.value();
    }

    /**
     * Soft delete the codelist.
     */
    async deleteCodelistRaw(requestParameters: CodelistApiDeleteCodelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling deleteCodelist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Soft delete the codelist.
     */
    async deleteCodelist(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCodelistRaw({ code: code }, initOverrides);
    }

    /**
     * Soft delete codelists.
     */
    async deleteCodelistsRaw(requestParameters: CodelistApiDeleteCodelistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeleteCodelistsOutput>>> {
        if (requestParameters.deleteCodelistsInput === null || requestParameters.deleteCodelistsInput === undefined) {
            throw new runtime.RequiredError('deleteCodelistsInput','Required parameter requestParameters.deleteCodelistsInput was null or undefined when calling deleteCodelists.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteCodelistsInputToJSON(requestParameters.deleteCodelistsInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeleteCodelistsOutputFromJSON));
    }

    /**
     * Soft delete codelists.
     */
    async deleteCodelists(deleteCodelistsInput: DeleteCodelistsInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeleteCodelistsOutput>> {
        const response = await this.deleteCodelistsRaw({ deleteCodelistsInput: deleteCodelistsInput }, initOverrides);
        return await response.value();
    }

    /**
     * Get codelist by code.
     */
    async getByCodeRaw(requestParameters: CodelistApiGetByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Codelist>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistFromJSON(jsonValue));
    }

    /**
     * Get codelist by code.
     */
    async getByCode(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Codelist> {
        const response = await this.getByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Search by specific criteria and return matching records.
     */
    async getByCriteriaRaw(requestParameters: CodelistApiGetByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelist>> {
        if (requestParameters.codelistCriteria === null || requestParameters.codelistCriteria === undefined) {
            throw new runtime.RequiredError('codelistCriteria','Required parameter requestParameters.codelistCriteria was null or undefined when calling getByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistCriteriaToJSON(requestParameters.codelistCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistFromJSON(jsonValue));
    }

    /**
     * Search by specific criteria and return matching records.
     */
    async getByCriteria(codelistCriteria: CodelistCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelist> {
        const response = await this.getByCriteriaRaw({ codelistCriteria: codelistCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Get all changed items between times.
     */
    async getChangesRaw(requestParameters: CodelistApiGetChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelist>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getChanges.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/changes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistFromJSON(jsonValue));
    }

    /**
     * Get all changed items between times.
     */
    async getChanges(dateFrom: string, page?: number, size?: number, dateTo?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelist> {
        const response = await this.getChangesRaw({ dateFrom: dateFrom, page: page, size: size, dateTo: dateTo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCodeAcronymMapRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-code-acronym-map`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getCodeAcronymMap(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: string; }> {
        const response = await this.getCodeAcronymMapRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get records by pageable.
     */
    async getCodelistsRaw(requestParameters: CodelistApiGetCodelistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelist>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistFromJSON(jsonValue));
    }

    /**
     * Get records by pageable.
     */
    async getCodelists(page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelist> {
        const response = await this.getCodelistsRaw({ page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLastGenerateItemCodeRaw(requestParameters: CodelistApiGetLastGenerateItemCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getLastGenerateItemCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/last-generate-item-code/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getLastGenerateItemCode(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getLastGenerateItemCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async missingAttributesCheckRaw(requestParameters: CodelistApiMissingAttributesCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Csv>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling missingAttributesCheck.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/missing-attributes-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CsvFromJSON(jsonValue));
    }

    /**
     */
    async missingAttributesCheck(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Csv> {
        const response = await this.missingAttributesCheckRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     */
    async missingAttributesCheck1Raw(requestParameters: CodelistApiMissingAttributesCheck1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/missing-attributes-check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async missingAttributesCheck1(code?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.missingAttributesCheck1Raw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async missingAttributesGetRaw(requestParameters: CodelistApiMissingAttributesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling missingAttributesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/missing-attributes-get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async missingAttributesGet(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.missingAttributesGetRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     * Update the codelist.
     */
    async updateRaw(requestParameters: CodelistApiUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Codelist>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling update.');
        }

        if (requestParameters.codelistInput === null || requestParameters.codelistInput === undefined) {
            throw new runtime.RequiredError('codelistInput','Required parameter requestParameters.codelistInput was null or undefined when calling update.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistInputToJSON(requestParameters.codelistInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistFromJSON(jsonValue));
    }

    /**
     * Update the codelist.
     */
    async update(code: number, codelistInput: CodelistInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Codelist> {
        const response = await this.updateRaw({ code: code, codelistInput: codelistInput }, initOverrides);
        return await response.value();
    }

}
