/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportProcessInfoSo
 */
export interface ExportProcessInfoSo {
    /**
     * 
     * @type {string}
     * @memberof ExportProcessInfoSo
     */
    exportStatus?: ExportProcessInfoSoExportStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ExportProcessInfoSo
     */
    percentage?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportProcessInfoSo
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportProcessInfoSo
     */
    message?: string;
}


/**
 * @export
 */
export const ExportProcessInfoSoExportStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Finished: 'FINISHED',
    Error: 'ERROR'
} as const;
export type ExportProcessInfoSoExportStatusEnum = typeof ExportProcessInfoSoExportStatusEnum[keyof typeof ExportProcessInfoSoExportStatusEnum];


/**
 * Check if a given object implements the ExportProcessInfoSo interface.
 */
export function instanceOfExportProcessInfoSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExportProcessInfoSoFromJSON(json: any): ExportProcessInfoSo {
    return ExportProcessInfoSoFromJSONTyped(json, false);
}

export function ExportProcessInfoSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportProcessInfoSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportStatus': !exists(json, 'exportStatus') ? undefined : json['exportStatus'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ExportProcessInfoSoToJSON(value?: ExportProcessInfoSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportStatus': value.exportStatus,
        'percentage': value.percentage,
        'error': value.error,
        'message': value.message,
    };
}

