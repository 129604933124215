import { CodelistAttributeDataTypeEnum, CodelistItem } from '../../openapi/codelist';
import { isCodeItemFromCodelist } from '../../utils/codelistHelper';
import {
    ArithmeticOperatorTypeEnum,
    ComparisonOperatorTypeEnum,
    ValidationTypeEnum,
    LogicalOperatorTypeEnum,
    OperatorTypeEnum,
    ValueTypeEnum,
    ConversionTypeEnum
} from '../../utils/enums';
import { isNotBlank } from '../../utils/stringUtils';
import { ElementType, ExpressionType, FormulaType } from './FormulaEditor';

type SplittedElementPartsWithStringExpression = {
    operator: {
        type: OperatorTypeEnum;
        value: ArithmeticOperatorTypeEnum | ComparisonOperatorTypeEnum | LogicalOperatorTypeEnum | null;
    };
    expression1: string;
    expression2: string;
};

type EmptyExpression = { type: ValueTypeEnum; value: null; acronym: null; detCodeIntAtt: null };

/**
 * Prevedie rôzne operátor enumy na string
 * @param operator
 * @returns
 */
export const operatorToString = (operator: ArithmeticOperatorTypeEnum | ComparisonOperatorTypeEnum | LogicalOperatorTypeEnum | null) => {
    switch (operator) {
        case 'ADDITION':
            return '+';
        case 'SUBTRACTION':
            return '-';
        case 'MULTIPLICATION':
            return '*';
        case 'DIVISION':
            return '/';
        case 'GRATER_THAN':
            return '>';
        case 'LESS_THAN':
            return '<';
        case 'GRATER_THAN_OR_EQUAL':
            return '>=';
        case 'LESS_THAN_OR_EQUAL':
            return '<=';
        case 'EQUAL':
            return '=';
        case 'AND':
            return '&&';
        case 'OR':
            return '||';
        case null:
            return 'neplatný operátor';
        default:
            return 'neznámy operátor';
    }
};

/**
 * Prevedie stringovú hodnotu na správny enum
 * @param operator
 * @returns
 */
const stringToOperator = (operator: string) => {
    switch (operator) {
        case '+':
            return ArithmeticOperatorTypeEnum.Addition;
        case '-':
            return ArithmeticOperatorTypeEnum.Subtraction;
        case '*':
            return ArithmeticOperatorTypeEnum.Multiplication;
        case '/':
            return ArithmeticOperatorTypeEnum.Division;
        case '>':
            return ComparisonOperatorTypeEnum.GreaterThan;
        case '<':
            return ComparisonOperatorTypeEnum.LessThan;
        case '>=':
            return ComparisonOperatorTypeEnum.GreaterThanOrEqual;
        case '<=':
            return ComparisonOperatorTypeEnum.LessThanOrEqual;
        case '=':
            return ComparisonOperatorTypeEnum.Equal;
        case '&&':
            return LogicalOperatorTypeEnum.And;
        case '||':
            return LogicalOperatorTypeEnum.Or;
        default:
            return null;
    }
};

/**
 *  Metóda na prevod expression na string
 *
 *  Pravidlá:
 *  hodnoty, ktoré sú číselníkové položky číselníka DETERMINANT => v [] zobrazujem kód číselníkovej položky napr. [48000232] => expression.type === ValueTypeEnum.Determinant,
 *  ak hodnota nie je číselníková položka, zobrazím ju bez [] => expression.type === ValueTypeEnum.Constant, napr. 3
 *  v prípade číselníkovej položky v [], a ak je formulaType typu ConversionTypeEnum zobrazím len kód napr [48000232]
 *  v prípade číselníkovej položky v [], a ak je formulaType typu ValidationTypeEnum zobrazím okrem kódu aj pomlčku a atribút medzinárodný kód ukazovateľa (det_code_int), ak tento atribút nie je uvedený, tak zobrazím akronym číselníkovej položky napr. [48000160-A032] v prípade kód-akronym, alebo [48000146-4.1.12] v prípade kód-medzinárodný kód ukazovateľa
 * @param expression
 * @param formulaType
 * @param showCodelistItemAttributes
 * @returns
 */
const expressionToString = (
    expression: ElementType | ExpressionType,
    formulaType: ValidationTypeEnum | ConversionTypeEnum,
    showCodelistItemAttributes: boolean
) => {
    if ('parentheses' in expression) {
        return elementToString(expression, formulaType, showCodelistItemAttributes);
    }
    if (expression.value === null) {
        return '';
    }
    if (expression.type !== ValueTypeEnum.Determinant) {
        return expression.value; // expression.type je ValueTypeEnum.Constant
    }
    if (showCodelistItemAttributes && Object.values(ValidationTypeEnum).includes(formulaType as ValidationTypeEnum)) {
        return `[${expression.value}-${expression.detCodeIntAtt ? expression.detCodeIntAtt : expression.acronym}]`;
    }

    // OSTATNÉ PRÍPADY:
    // showCodelistItemAttributes === false, nechcem zobrazovať atribúty (medzinárodný kód ukazovateľa alebo akronym) číselníkovej položky číselníka DETERMINANT
    // formulaType je typu ConversionTypeEnum, taktiež nechcem zobrazovať atribúty
    return `[${expression.value}]`;
};

const elementToString = (
    element: ElementType,
    formulaType: ValidationTypeEnum | ConversionTypeEnum,
    showCodelistItemAttributes: boolean
) => {
    const expression: string = `${expressionToString(element.expression1, formulaType, showCodelistItemAttributes)} ${operatorToString(element.operator.value)} ${expressionToString(element.expression2, formulaType, showCodelistItemAttributes)}`;
    return element.parentheses ? `(${expression})` : expression;
};

/**
 * Metóda na prevod vzorca na string
 * @param  formula Vzorec, ktorý chcem zmeniť na string
 * @param showCodelistItemAttributes Definícia, či chcem vo výslednom stringu v prípade číselníkovej položky zobraziť aj atribút medzinárodný kód ukazovateľa a ak nie je uvedený, tak akronym
 * @returns Výsledný vzorec
 */
export const formulaToString = (formula: FormulaType, showCodelistItemAttributes: boolean = false): string => {
    const element1 = formula.element1 ? elementToString(formula.element1, formula.type, showCodelistItemAttributes) : '';
    const element2 = formula.element2 ? elementToString(formula.element2, formula.type, showCodelistItemAttributes) : '';

    switch (formula.type) {
        case ValidationTypeEnum.Basic:
        case ValidationTypeEnum.LogOp:
        case ConversionTypeEnum.Conversion:
            if (isNotBlank(element1)) {
                return element1;
            }
            break;
        case ValidationTypeEnum.OrOp:
            if (isNotBlank(element1) && isNotBlank(element2)) {
                return `OR(${element1} , ${element2})`;
            }
            break;
        case ValidationTypeEnum.IfThen:
            if (isNotBlank(element1) && isNotBlank(element2)) {
                return `IF_THEN(${element1} , ${element2})`;
            }
            break;
    }

    throw new Error('Neplaný vzorec');
};

const isWrappedByEnclosures = (expression: string, enclosure: '[]' | '()'): boolean => {
    const e = expression.trim();
    if (e.startsWith(enclosure[0]) && e.endsWith(enclosure[1])) {
        let openEnclosures = 0;
        return e.split('').every((c, idx, arr) => {
            if (c === enclosure[0]) {
                openEnclosures++;
            }
            if (c === enclosure[1]) {
                openEnclosures--;
            }
            return openEnclosures > 0 || (idx === arr.length - 1 && openEnclosures === 0);
        });
    }
    return false;
};

const allOperators = (operator: OperatorTypeEnum) => {
    if (operator === OperatorTypeEnum.Arithmetic) {
        return getAllOperators(ArithmeticOperatorTypeEnum);
    } else if (operator === OperatorTypeEnum.Comparison) {
        return getAllOperators(ComparisonOperatorTypeEnum);
    } else if (operator === OperatorTypeEnum.Logical) {
        return getAllOperators(LogicalOperatorTypeEnum);
    } else return [];
};

const getAllOperators = <T extends LogicalOperatorTypeEnum | ComparisonOperatorTypeEnum | ArithmeticOperatorTypeEnum>(o: {
    [s: string]: T;
}): string[] => {
    return Object.values(o)
        .map((v) => operatorToString(v))
        .sort((a, b) => b.length - a.length);
};

const numberOfComparisonOperators = (part: string) => {
    // vráti počet všetkých porovnávacích operátorov v časti vzorca - zosortované podľa dĺžky
    const sortedComparisonOperators = getAllOperators(ComparisonOperatorTypeEnum);
    let newPart = part;
    let count = 0;

    const editPart = (startIndex: number, length: number) => {
        const rest = newPart.substring(0, startIndex) + newPart.substring(startIndex + length); // vyhodím zo stringu operátor
        newPart = rest;
        count++;
    };

    sortedComparisonOperators.forEach((s) => {
        while (newPart.indexOf(s) > -1) {
            const index = newPart.indexOf(s);
            editPart(index, s.length);
        }
    });
    return count;
};

const parseDeterminant = async (
    value: string,
    getCodelistItem: (code: number, showErrorToast: boolean) => Promise<CodelistItem>,
    showGetCodelistItemErrorToast: boolean
): Promise<ExpressionType> => {
    const v = value.substring(1, value.length - 1).trim();
    if (!isNaN(+v) && isCodeItemFromCodelist(+v, ENV.CODELIST_DETERMINANT)) {
        const ci = await getCodelistItem(+v, showGetCodelistItemErrorToast);
        const detCodeIntAttribute = ci.attributes.find((a) => a.key === 'det_code_int')?.value ?? null;
        return {
            type: ValueTypeEnum.Determinant,
            value: +v,
            acronym: ci.acronym,
            detCodeIntAtt: detCodeIntAttribute
        };
    } else {
        throw new Error(`Hodnota "${value}" nie je platná číselníková položka z číselníka "Zoznam ukazovatelov"`);
    }
};

const parseConstant = async (value: string): Promise<ExpressionType> => {
    if (!isNaN(+value)) {
        return {
            type: ValueTypeEnum.Constant,
            value: +value,
            acronym: null,
            detCodeIntAtt: null
        };
    } else {
        throw new Error(`Hodnota "${value}" nie je platné číslo`);
    }
};

const parseValue = async (
    part: string,
    getCodelistItem: (code: number, showErrorToast: boolean) => Promise<CodelistItem>,
    showGetCodelistItemErrorToast: boolean
): Promise<ExpressionType> => {
    if (isWrappedByEnclosures(part, '[]')) {
        return await parseDeterminant(part, getCodelistItem, showGetCodelistItemErrorToast);
    } else {
        return await parseConstant(part);
    }
};

const findNextOperator = (termOrExpression: string, operators: string[], position?: number) => {
    const operator = operators
        .map((ao) => {
            let op;
            if (ao === '-') {
                // ak je operator '-', nájdem len taký operátor, za ktorým sa nenachádza číslo, pretože inak to nie je operátor mínus, ale záporné číslo
                // regex = /(?<=^.{5}.*)(-[^0-9])/; // (-[^0-9]) => musí obsahovať mínus - a za tým sa nemôže (^) nachádzať číslo od 0-9 [0-9]; (?<=^.{5}) => päť ({5}) hocijakých znakov (.) zo začiatku (^) ignoruje; .* => za tými ignorovanými je ľubovolný počet (*) hocijakých (.) znakov
                // kvôli dynamickému skladaniu musím regex rozdeliť na časti
                const newPosition = position !== undefined ? position : 0;
                const regexPart1 = '(?<=^.{';
                const regexPart2 = '}.*)(-[^0-9])';
                const regex = new RegExp(regexPart1 + newPosition + regexPart2);
                op = {
                    operator: ao,
                    index: termOrExpression.search(regex)
                };
            } else {
                op = {
                    operator: ao,
                    index: termOrExpression.indexOf(ao, position)
                };
            }
            return op;
        })
        .filter((val) => val.index !== -1)
        .sort((a, b) => a.index - b.index)
        .find(() => true);
    return operator;
};

// nájde prvý operátor mimo zátvoriek a rozdelí na ňom
const splitOnRootOperator = (
    expression: string,
    operatorType: OperatorTypeEnum,
    operators: string[]
): SplittedElementPartsWithStringExpression => {
    if (operators.some((ao) => expression.includes(ao))) {
        let firstOperator = findNextOperator(expression, operators);

        let firstParenthesis = expression.indexOf('(');
        if (firstParenthesis > -1) {
            while (firstOperator && firstOperator.index >= firstParenthesis && firstParenthesis !== -1) {
                let openParenthesis = 0;
                for (let i = firstParenthesis; i < expression.length; i++) {
                    if (expression[i] === '(') openParenthesis++;
                    if (expression[i] === ')') openParenthesis--;
                    if (openParenthesis === 0) {
                        firstParenthesis = expression.indexOf('(', i + 1);
                        firstOperator = findNextOperator(expression, operators, i + 1);
                        break;
                    }
                }
            }
        }
        // pre prípad bez zátvoriek vrátime rozdelený string na prvom operátore v poradí
        if (firstOperator) {
            const ex1 = expression.substring(0, firstOperator.index).trim();
            const ex2 = expression.substring(firstOperator.index + firstOperator.operator.length).trim();
            return {
                operator: {
                    type: operatorType,
                    value: stringToOperator(firstOperator.operator)
                },
                expression1: ex1,
                expression2: ex2
            };
        }
    }
    // ak neobsahuje žiadny root operátor
    const name =
        operatorType === OperatorTypeEnum.Logical
            ? 'logický'
            : operatorType === OperatorTypeEnum.Comparison
              ? 'porovnávací'
              : 'aritmetický';
    throw new Error(`Výraz "${expression}" neobsahuje nadradený ${name} operátor`);
};

/**
 * Metóda na rozdelenie výrazu v mieste operátora
 * @param validationType Typ validácie: 'validate' - ak chýba časť vzorca, zobrazí chybu, 'edit'- ak chýba časť vzorca, v editore sa zobrazí null hodnota
 * @param part Časť, ktorú chcem rozdeliť
 * @param hasOperator Na akom operátorovi sa má výraz rozdeliť, v prírade, že tento operátor časť výrazu obsahuje
 * @param hasNotOperator Na akom operátorovi sa má výraz rozdeliť, v prírade, že výraz neobsahuje operátor z predchádzajúceho parametra. Môže byť null, vtedy už výraz nechcem rozdeliť na žiadnom operátorovi, ale chcem vytvoriť výraz pomocou konštanty alebo číselníkovej položky
 * @param getCodelistItem Funkcia, ktorou získam číselníkovú položku
 * @param showGetCodelistItemErrorToast Parameter, ktorý definuje, či sa pri zavolaní funkcie getCodelistItem má alebo nemá zobraziť toast s chybovou správou
 */
const splitPart = (
    validationType: 'validate' | 'edit',
    part: string,
    hasOperator: OperatorTypeEnum,
    hasNotOperator: OperatorTypeEnum | null,
    getCodelistItem: (code: number, showErrorToast: boolean) => Promise<CodelistItem>,
    showGetCodelistItemErrorToast: boolean
): Promise<ElementType> | Promise<ExpressionType> => {
    if (findNextOperator(part, allOperators(hasOperator))) {
        // ak nájde operátora (hasOperator), tak výraz rozdeli na ňom
        return splitOnOperator(hasOperator, part, validationType, getCodelistItem, showGetCodelistItemErrorToast);
    } else {
        // ak nenájde operátora (hasOperator), rozdelím výraz na operátorovi (hasNotOperator) alebo ho ďalej nerozdeľujem (hasNotOperator === null) a vytvorim výraz typu ExpressionType s číselníkovou položkou alebo konštantou
        if (hasNotOperator) {
            return splitOnOperator(hasNotOperator, part, validationType, getCodelistItem, showGetCodelistItemErrorToast);
        } else {
            return parseValue(part, getCodelistItem, showGetCodelistItemErrorToast);
        }
    }
};

/**
 * Metóda na rozdelenie výrazu v mieste operátora
 * @param operator Na akom operátorovi sa má výraz rozdeliť
 * @param expression Výraz, ktorý chcem rozdeliť
 * @param validationType Typ validácie: 'validate' - ak chýba časť vzorca, zobrazí chybu, 'edit'- ak chýba časť vzorca, v editore sa zobrazí null hodnota
 * @param getCodelistItem Funkcia, ktorou získam číselníkovú položku
 * @param showGetCodelistItemErrorToast Parameter, ktorý definuje, či sa pri zavolaní funkcie getCodelistItem má alebo nemá zobraziť toast s chybovou správou
 * @returns
 */
const splitOnOperator = async (
    operator: OperatorTypeEnum,
    expression: string,
    validationType: 'validate' | 'edit',
    getCodelistItem: (code: number, showErrorToast: boolean) => Promise<CodelistItem>,
    showGetCodelistItemErrorToast: boolean
) => {
    // do funkcie posielam otrimovany expression
    const parentheses = isWrappedByEnclosures(expression, '()');
    let newExpresion = expression;
    if (parentheses) {
        newExpresion = expression.substring(1, expression.length - 1).trim();
    }

    // v prípade, že výraz rozdeľujem na porovnávacom operátorovi, skontrolujem, či sa vo výraze nachádza len jeden porovnávací operátor
    if (operator === OperatorTypeEnum.Comparison) {
        const comparisonOperatorsCount = numberOfComparisonOperators(newExpresion);
        if (comparisonOperatorsCount !== 1) {
            throw new Error(`Výraz "${expression}" musí obsahovať jeden porovnávací operátor`);
        }
    }

    const parts: SplittedElementPartsWithStringExpression = splitOnRootOperator(newExpresion, operator, allOperators(operator));
    const emptyExpression: EmptyExpression = { type: ValueTypeEnum.Determinant, value: null, acronym: null, detCodeIntAtt: null };
    const result = {
        parentheses: parentheses,
        operator: parts.operator,
        expression1: parts.expression1
            ? await splitPart(
                  validationType,
                  parts.expression1,
                  operator === OperatorTypeEnum.Logical ? OperatorTypeEnum.Logical : OperatorTypeEnum.Arithmetic, // v prípade operator === OperatorTypeEnum.Logical rozdelím výraz na mieste logického operátora (OperatorTypeEnum.Logical), v prípade operator === OperatorTypeEnum.Comparison alebo operator === OperatorTypeEnum.Arithmetic rozdelím výraz na mieste aritmetického operátora (OperatorTypeEnum.Arithmetic)
                  operator === OperatorTypeEnum.Logical ? OperatorTypeEnum.Comparison : null, // v prípade operator === OperatorTypeEnum.Logical ak nenajde logický operátor (z predchádzajúceho parametra) rozdelim výraz na mieste porovnávacieho operátora (OperatorTypeEnum.Comparison), v prípade operator === OperatorTypeEnum.Comparison alebo operator === OperatorTypeEnum.Arithmetic výraz nerozdeľujem na žiadny iný, zistím hodnotu konštantu alebo číselníkovej položky
                  getCodelistItem,
                  showGetCodelistItemErrorToast
              )
            : emptyExpression,
        expression2: parts.expression2
            ? await splitPart(
                  validationType,
                  parts.expression2,
                  operator === OperatorTypeEnum.Logical ? OperatorTypeEnum.Logical : OperatorTypeEnum.Arithmetic, // v prípade operator === OperatorTypeEnum.Logical rozdelím výraz na mieste logického operátora (OperatorTypeEnum.Logical), v prípade operator === OperatorTypeEnum.Comparison alebo operator === OperatorTypeEnum.Arithmetic rozdelím výraz na mieste aritmetického operátora (OperatorTypeEnum.Arithmetic)
                  operator === OperatorTypeEnum.Logical ? OperatorTypeEnum.Comparison : null, // v prípade operator === OperatorTypeEnum.Logical ak nenajde logický operátor (z predchádzajúceho parametra) rozdelim výraz na mieste porovnávacieho operátora (OperatorTypeEnum.Comparison), v prípade operator === OperatorTypeEnum.Comparison alebo operator === OperatorTypeEnum.Arithmetic výraz nerozdeľujem na žiadny iný, zistím hodnotu konštantu alebo číselníkovej položky
                  getCodelistItem,
                  showGetCodelistItemErrorToast
              )
            : emptyExpression
    };

    if (!parts.expression1 || !parts.expression2) {
        if (validationType === 'edit') {
            return result;
        } else {
            throw new Error('Časť výrazu nie je zadaná');
        }
    } else {
        return result;
    }
};

const determineValidationEquationType = (formula: string) => {
    if (formula.startsWith('OR')) {
        if (!(formula.startsWith('OR(') && formula.endsWith(')'))) {
            throw new Error('Zadaný vzorec nespĺňa tvar "OR(výraz , výraz)"');
        }
        return ValidationTypeEnum.OrOp;
    }
    if (formula.startsWith('IF')) {
        if (!(formula.startsWith('IF_THEN(') && formula.endsWith(')'))) {
            throw new Error('Zadaný vzorec nespĺňa tvar "IF_THEN(výraz , výraz)"');
        }
        return ValidationTypeEnum.IfThen;
    }
    if (getAllOperators(LogicalOperatorTypeEnum).some((ao) => formula.includes(ao))) {
        return ValidationTypeEnum.LogOp;
    }
    return ValidationTypeEnum.Basic;
};

/**
 * Metóda na rozdelenie OrOp alebo IfThen vzorca
 * @param formula Vzorec, ktorý chcem rozdeliť
 * @param type Typ vzorca, ktorý chcem rozdeliť
 * @returns Elementy vzorca
 */
const splitFormula = (formula: string, type: ValidationTypeEnum.OrOp | ValidationTypeEnum.IfThen): [string, string] => {
    const expressionParts = formula
        .substring(type === ValidationTypeEnum.OrOp ? 3 : 8, formula.length - 1)
        .split(',')
        .map((v) => v.trim());

    if (expressionParts.length !== 2) {
        throw new Error(
            `Zadaný vzorec "${formula}" nespĺňa tvar "${type === ValidationTypeEnum.OrOp ? 'OR(výraz , výraz)' : 'IF_THEN(výraz , výraz)'}"`
        );
    }
    return expressionParts as [string, string];
};

/**
 * @param expressionPart Čast vzorca OrOp alebo IfThen na spracovanie
 * @param validationType Typ validácie: 'validate' - ak chýba časť vzorca, zobrazí chybu, 'edit'- ak chýba časť vzorca, v editore sa zobrazí null hodnota
 * @param getCodelistItem Funkcia, ktorou získam číselníkovú položku
 * @param showGetCodelistItemErrorToast Parameter, ktorý definuje, či sa pri zavolaní funkcie getCodelistItem má alebo nemá zobraziť toast s chybovou správou
 * @returns Spracovaný element
 */
const processElement = async (
    expressionPart: string,
    validationType: 'validate' | 'edit',
    getCodelistItem: (code: number, showErrorToast: boolean) => Promise<CodelistItem>,
    showGetCodelistItemErrorToast: boolean
) => {
    // zistím, či element obsahuje logický operátor
    const elementHasLogicalOperator = getAllOperators(LogicalOperatorTypeEnum).some((ao) => expressionPart.includes(ao));

    return await splitOnOperator(
        elementHasLogicalOperator ? OperatorTypeEnum.Logical : OperatorTypeEnum.Comparison,
        expressionPart,
        validationType,
        getCodelistItem,
        showGetCodelistItemErrorToast
    );
};

/**
 * @param formula Vzorec, ktorý chcem parsovať
 * @param formulaDataType Dátový typ atribútu, ktorému zadám vzorec na parsovanie
 * @param validationType Typ validácie: 'validate' - ak chýba časť vzorca, zobrazí chybu, 'edit'- ak chýba časť vzorca, v editore sa zobrazí null hodnota
 * @param getCodelistItem Funkcia, ktorou získam číselníkovú položku
 * @param showGetCodelistItemErrorToast Parameter, ktorý definuje, či sa pri zavolaní funkcie getCodelistItem má alebo nemá zobraziť toast s chybovou správou
 * @returns Sparsovaný vzorec
 */
export const parseFormula = async (
    formula: string | null,
    formulaDataType: typeof CodelistAttributeDataTypeEnum.ValidationEquation | typeof CodelistAttributeDataTypeEnum.ConversionEquation,
    validationType: 'validate' | 'edit', // ak je 'validate' a chýba časť výrazu, spraví throw new Error (použité pri pri ukladaní vzorca), ak je 'edit' a chýba časť výrazu, nataví value na null a editor sa otvorí, je možné vzorec upravovať
    getCodelistItem: (code: number, showErrorToast: boolean) => Promise<CodelistItem>,
    showGetCodelistItemErrorToast: boolean
): Promise<FormulaType | null> => {
    try {
        if (isNotBlank(formula)) {
            const f = formula.trim();
            if (formulaDataType === CodelistAttributeDataTypeEnum.ValidationEquation) {
                const type = determineValidationEquationType(f);
                switch (type) {
                    case ValidationTypeEnum.OrOp:
                    case ValidationTypeEnum.IfThen:
                        const expressionParts = splitFormula(f, type);
                        const element1 = await processElement(
                            expressionParts[0],
                            validationType,
                            getCodelistItem,
                            showGetCodelistItemErrorToast
                        );
                        const element2 = await processElement(
                            expressionParts[1],
                            validationType,
                            getCodelistItem,
                            showGetCodelistItemErrorToast
                        );
                        return {
                            type,
                            element1,
                            element2
                        };
                    case ValidationTypeEnum.LogOp:
                        return {
                            type,
                            element1: await splitOnOperator(
                                OperatorTypeEnum.Logical,
                                f,
                                validationType,
                                getCodelistItem,
                                showGetCodelistItemErrorToast
                            ),
                            element2: null
                        };
                    case ValidationTypeEnum.Basic:
                        return {
                            type,
                            element1: await splitOnOperator(
                                OperatorTypeEnum.Comparison,
                                f,
                                validationType,
                                getCodelistItem,
                                showGetCodelistItemErrorToast
                            ),
                            element2: null
                        };
                }
            } else {
                return {
                    type: ConversionTypeEnum.Conversion,
                    element1: await splitOnOperator(
                        OperatorTypeEnum.Arithmetic,
                        f,
                        validationType,
                        getCodelistItem,
                        showGetCodelistItemErrorToast
                    ),
                    element2: null
                };
            }
        }
        // vo vzorci su len medzery alebo je null;
        return null;
    } catch (e: any) {
        throw e;
    }
};
