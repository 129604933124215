/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicalAttribute
 */
export interface TechnicalAttribute {
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttribute
     */
    creator: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttribute
     */
    dateCreated: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttribute
     */
    modifier?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttribute
     */
    dateModified?: string;
    /**
     * 
     * @type {number}
     * @memberof TechnicalAttribute
     */
    historyGroupId: number | null;
}

/**
 * Check if a given object implements the TechnicalAttribute interface.
 */
export function instanceOfTechnicalAttribute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "dateCreated" in value;
    isInstance = isInstance && "historyGroupId" in value;

    return isInstance;
}

export function TechnicalAttributeFromJSON(json: any): TechnicalAttribute {
    return TechnicalAttributeFromJSONTyped(json, false);
}

export function TechnicalAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': json['creator'],
        'dateCreated': json['dateCreated'],
        'modifier': !exists(json, 'modifier') ? undefined : json['modifier'],
        'dateModified': !exists(json, 'dateModified') ? undefined : json['dateModified'],
        'historyGroupId': json['historyGroupId'],
    };
}

export function TechnicalAttributeToJSON(value?: TechnicalAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': value.creator,
        'dateCreated': value.dateCreated,
        'modifier': value.modifier,
        'dateModified': value.dateModified,
        'historyGroupId': value.historyGroupId,
    };
}

