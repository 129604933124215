/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    required: boolean;
}

/**
 * Check if a given object implements the Language interface.
 */
export function instanceOfLanguage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "required" in value;

    return isInstance;
}

export function LanguageFromJSON(json: any): Language {
    return LanguageFromJSONTyped(json, false);
}

export function LanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Language {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': json['language'],
        'code': json['code'],
        'required': json['required'],
    };
}

export function LanguageToJSON(value?: Language | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': value.language,
        'code': value.code,
        'required': value.required,
    };
}

