export const isEmpty = (s: undefined | null | string): boolean => {
    if (s === undefined || s === null) {
        return true;
    }
    if (s === '') {
        return true;
    }
    return false;
};

export const isBlank = (s: undefined | null | string): boolean => {
    if (isEmpty(s)) {
        return true;
    }
    if (s?.trim() === '') {
        return true;
    }
    return false;
};

export const isNotEmpty = (s: undefined | null | string): s is string => !isEmpty(s);
export const isNotBlank = (s: undefined | null | string): s is string => !isBlank(s);
