/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.36.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInputRequired } from './CodelistItemInputRequired';
import {
    CodelistItemInputRequiredFromJSON,
    CodelistItemInputRequiredFromJSONTyped,
    CodelistItemInputRequiredToJSON,
} from './CodelistItemInputRequired';

/**
 * 
 * @export
 * @interface WaterShapeInput
 */
export interface WaterShapeInput {
    /**
     * 
     * @type {string}
     * @memberof WaterShapeInput
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeInput
     */
    riverKmClosest: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeInput
     */
    riverKmFurthest: number;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof WaterShapeInput
     */
    basinType: CodelistItemInputRequired;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof WaterShapeInput
     */
    wbCharacterType: CodelistItemInputRequired;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof WaterShapeInput
     */
    wbGroupType: CodelistItemInputRequired;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof WaterShapeInput
     */
    wbType: CodelistItemInputRequired;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof WaterShapeInput
     */
    cycleType: CodelistItemInputRequired;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeInput
     */
    jtskXStart: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeInput
     */
    jtskXEnd: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeInput
     */
    jtskYStart: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeInput
     */
    jtskYEnd: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeInput
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeInput
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeInput
     */
    code: string;
}

/**
 * Check if a given object implements the WaterShapeInput interface.
 */
export function instanceOfWaterShapeInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "riverKmClosest" in value;
    isInstance = isInstance && "riverKmFurthest" in value;
    isInstance = isInstance && "basinType" in value;
    isInstance = isInstance && "wbCharacterType" in value;
    isInstance = isInstance && "wbGroupType" in value;
    isInstance = isInstance && "wbType" in value;
    isInstance = isInstance && "cycleType" in value;
    isInstance = isInstance && "jtskXStart" in value;
    isInstance = isInstance && "jtskXEnd" in value;
    isInstance = isInstance && "jtskYStart" in value;
    isInstance = isInstance && "jtskYEnd" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function WaterShapeInputFromJSON(json: any): WaterShapeInput {
    return WaterShapeInputFromJSONTyped(json, false);
}

export function WaterShapeInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterShapeInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'riverKmClosest': json['riverKmClosest'],
        'riverKmFurthest': json['riverKmFurthest'],
        'basinType': CodelistItemInputRequiredFromJSON(json['basinType']),
        'wbCharacterType': CodelistItemInputRequiredFromJSON(json['wbCharacterType']),
        'wbGroupType': CodelistItemInputRequiredFromJSON(json['wbGroupType']),
        'wbType': CodelistItemInputRequiredFromJSON(json['wbType']),
        'cycleType': CodelistItemInputRequiredFromJSON(json['cycleType']),
        'jtskXStart': json['jtskXStart'],
        'jtskXEnd': json['jtskXEnd'],
        'jtskYStart': json['jtskYStart'],
        'jtskYEnd': json['jtskYEnd'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'code': json['code'],
    };
}

export function WaterShapeInputToJSON(value?: WaterShapeInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'riverKmClosest': value.riverKmClosest,
        'riverKmFurthest': value.riverKmFurthest,
        'basinType': CodelistItemInputRequiredToJSON(value.basinType),
        'wbCharacterType': CodelistItemInputRequiredToJSON(value.wbCharacterType),
        'wbGroupType': CodelistItemInputRequiredToJSON(value.wbGroupType),
        'wbType': CodelistItemInputRequiredToJSON(value.wbType),
        'cycleType': CodelistItemInputRequiredToJSON(value.cycleType),
        'jtskXStart': value.jtskXStart,
        'jtskXEnd': value.jtskXEnd,
        'jtskYStart': value.jtskYStart,
        'jtskYEnd': value.jtskYEnd,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'code': value.code,
    };
}

