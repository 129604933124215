/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanResponse,
  CodelistItem,
  CodelistItemCodesInput,
  CodelistItemCriteria,
  CodelistItemInput,
  CsvStatusResponse,
  DeleteCodelistsInput,
  DeleteCodelistsOutput,
  EndpointExceptionBody,
  Methodic,
  PageCodelistItem,
} from '../models/index';
import {
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    CodelistItemFromJSON,
    CodelistItemToJSON,
    CodelistItemCodesInputFromJSON,
    CodelistItemCodesInputToJSON,
    CodelistItemCriteriaFromJSON,
    CodelistItemCriteriaToJSON,
    CodelistItemInputFromJSON,
    CodelistItemInputToJSON,
    CsvStatusResponseFromJSON,
    CsvStatusResponseToJSON,
    DeleteCodelistsInputFromJSON,
    DeleteCodelistsInputToJSON,
    DeleteCodelistsOutputFromJSON,
    DeleteCodelistsOutputToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    MethodicFromJSON,
    MethodicToJSON,
    PageCodelistItemFromJSON,
    PageCodelistItemToJSON,
} from '../models/index';

export interface ItemApiCheckCsvRequest {
    uuid: string;
}

export interface ItemApiCheckUniqueAttributeRequest {
    codelistCode: number;
    attributeCode: string;
    attributeValue: string;
}

export interface ItemApiCreateItemRequest {
    codelistItemInput: CodelistItemInput;
}

export interface ItemApiDeleteItemRequest {
    code: number;
}

export interface ItemApiDeleteItemsRequest {
    deleteCodelistsInput: DeleteCodelistsInput;
}

export interface ItemApiDownloadCsvRequest {
    uuid: string;
}

export interface ItemApiExistsAcronymOnItemsRequest {
    codelistCode: number;
    acronym: string;
    code?: number;
}

export interface ItemApiExistsCodeOnItemsRequest {
    code: number;
}

export interface ItemApiExportCsvRequest {
    delimiter: string;
    headersOnly: boolean;
    codelistItemCriteria: CodelistItemCriteria;
}

export interface ItemApiExportListCsvRequest {
    delimiter: string;
    requestBody: Array<number>;
}

export interface ItemApiGetAllItemsByCodelistRequest {
    codelistCode: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ItemApiGetByAcronymRequest {
    codelistCode: number;
    acronym: string;
}

export interface ItemApiGetByCodelistCodeAcronymAndDateTimeRequest {
    codelistCode: number;
    acronym: string;
    dateTime: string;
}

export interface ItemApiGetItemByCodeRequest {
    code: number;
}

export interface ItemApiGetItemByCriteriaRequest {
    codelistItemCriteria: CodelistItemCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ItemApiGetItemByDateRequest {
    code: number;
    date: string;
}

export interface ItemApiGetItemByDateTimeRequest {
    code: number;
    date: string;
}

export interface ItemApiGetItemChangedRequest {
    dateFrom: string;
    page?: number;
    size?: number;
    dateTo?: string;
}

export interface ItemApiGetItemsByCodeRequest {
    code: number;
}

export interface ItemApiGetItemsByCodelistRequest {
    codelistCode: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ItemApiImportCsvRequest {
    delimiter: string;
    code: number;
    file: Blob;
}

export interface ItemApiNewVersionRequest {
    code: number;
    codelistItemInput: CodelistItemInput;
}

export interface ItemApiRestoreRequest {
    codelistItemCodesInput: CodelistItemCodesInput;
}

export interface ItemApiTransformCodesRequest {
    methodCodeInternational: string;
    determinantCodeInternational: string;
    dateTime: string;
}

export interface ItemApiUpdateItemRequest {
    code: number;
    codelistItemInput: CodelistItemInput;
}

export interface ItemApiValidateForCreateRequest {
    codelistItemInput: CodelistItemInput;
}

export interface ItemApiValidateForNewVersionRequest {
    code: number;
    codelistItemInput: CodelistItemInput;
}

export interface ItemApiValidateForUpdateRequest {
    code: number;
    codelistItemInput: CodelistItemInput;
}

/**
 * 
 */
export class ItemApi extends runtime.BaseAPI {

    /**
     */
    async checkCsvRaw(requestParameters: ItemApiCheckCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CsvStatusResponse>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling checkCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/check-csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CsvStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async checkCsv(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CsvStatusResponse> {
        const response = await this.checkCsvRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     * Checks the uniqueness of the value of the attribute on the codelist. It it finds any exists, gets all item codes where it finds the value.
     */
    async checkUniqueAttributeRaw(requestParameters: ItemApiCheckUniqueAttributeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.codelistCode === null || requestParameters.codelistCode === undefined) {
            throw new runtime.RequiredError('codelistCode','Required parameter requestParameters.codelistCode was null or undefined when calling checkUniqueAttribute.');
        }

        if (requestParameters.attributeCode === null || requestParameters.attributeCode === undefined) {
            throw new runtime.RequiredError('attributeCode','Required parameter requestParameters.attributeCode was null or undefined when calling checkUniqueAttribute.');
        }

        if (requestParameters.attributeValue === null || requestParameters.attributeValue === undefined) {
            throw new runtime.RequiredError('attributeValue','Required parameter requestParameters.attributeValue was null or undefined when calling checkUniqueAttribute.');
        }

        const queryParameters: any = {};

        if (requestParameters.codelistCode !== undefined) {
            queryParameters['codelistCode'] = requestParameters.codelistCode;
        }

        if (requestParameters.attributeCode !== undefined) {
            queryParameters['attributeCode'] = requestParameters.attributeCode;
        }

        if (requestParameters.attributeValue !== undefined) {
            queryParameters['attributeValue'] = requestParameters.attributeValue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/check-unique-attribute`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Checks the uniqueness of the value of the attribute on the codelist. It it finds any exists, gets all item codes where it finds the value.
     */
    async checkUniqueAttribute(codelistCode: number, attributeCode: string, attributeValue: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.checkUniqueAttributeRaw({ codelistCode: codelistCode, attributeCode: attributeCode, attributeValue: attributeValue }, initOverrides);
        return await response.value();
    }

    /**
     * Create and validate item. Return created item.
     */
    async createItemRaw(requestParameters: ItemApiCreateItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.codelistItemInput === null || requestParameters.codelistItemInput === undefined) {
            throw new runtime.RequiredError('codelistItemInput','Required parameter requestParameters.codelistItemInput was null or undefined when calling createItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemInputToJSON(requestParameters.codelistItemInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     * Create and validate item. Return created item.
     */
    async createItem(codelistItemInput: CodelistItemInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.createItemRaw({ codelistItemInput: codelistItemInput }, initOverrides);
        return await response.value();
    }

    /**
     * Soft delete the item.
     */
    async deleteItemRaw(requestParameters: ItemApiDeleteItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeleteCodelistsOutput>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling deleteItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeleteCodelistsOutputFromJSON));
    }

    /**
     * Soft delete the item.
     */
    async deleteItem(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeleteCodelistsOutput>> {
        const response = await this.deleteItemRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Soft delete items.
     */
    async deleteItemsRaw(requestParameters: ItemApiDeleteItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeleteCodelistsOutput>>> {
        if (requestParameters.deleteCodelistsInput === null || requestParameters.deleteCodelistsInput === undefined) {
            throw new runtime.RequiredError('deleteCodelistsInput','Required parameter requestParameters.deleteCodelistsInput was null or undefined when calling deleteItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteCodelistsInputToJSON(requestParameters.deleteCodelistsInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeleteCodelistsOutputFromJSON));
    }

    /**
     * Soft delete items.
     */
    async deleteItems(deleteCodelistsInput: DeleteCodelistsInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeleteCodelistsOutput>> {
        const response = await this.deleteItemsRaw({ deleteCodelistsInput: deleteCodelistsInput }, initOverrides);
        return await response.value();
    }

    /**
     * Download csv files.
     */
    async downloadCsvRaw(requestParameters: ItemApiDownloadCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling downloadCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.uuid !== undefined) {
            queryParameters['uuid'] = requestParameters.uuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/download-csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download csv files.
     */
    async downloadCsv(uuid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadCsvRaw({ uuid: uuid }, initOverrides);
        return await response.value();
    }

    /**
     * Checks the uniqueness of the acronym on the codelist. If the code of the item is specified, the uniqueness of the acronym is checked out of this code.
     */
    async existsAcronymOnItemsRaw(requestParameters: ItemApiExistsAcronymOnItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.codelistCode === null || requestParameters.codelistCode === undefined) {
            throw new runtime.RequiredError('codelistCode','Required parameter requestParameters.codelistCode was null or undefined when calling existsAcronymOnItems.');
        }

        if (requestParameters.acronym === null || requestParameters.acronym === undefined) {
            throw new runtime.RequiredError('acronym','Required parameter requestParameters.acronym was null or undefined when calling existsAcronymOnItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.codelistCode !== undefined) {
            queryParameters['codelistCode'] = requestParameters.codelistCode;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.acronym !== undefined) {
            queryParameters['acronym'] = requestParameters.acronym;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/acronym-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     * Checks the uniqueness of the acronym on the codelist. If the code of the item is specified, the uniqueness of the acronym is checked out of this code.
     */
    async existsAcronymOnItems(codelistCode: number, acronym: string, code?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.existsAcronymOnItemsRaw({ codelistCode: codelistCode, acronym: acronym, code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Checks the uniqueness of the code.
     */
    async existsCodeOnItemsRaw(requestParameters: ItemApiExistsCodeOnItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling existsCodeOnItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/code-exists/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     * Checks the uniqueness of the code.
     */
    async existsCodeOnItems(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.existsCodeOnItemsRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Export items to csv file.
     */
    async exportCsvRaw(requestParameters: ItemApiExportCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.delimiter === null || requestParameters.delimiter === undefined) {
            throw new runtime.RequiredError('delimiter','Required parameter requestParameters.delimiter was null or undefined when calling exportCsv.');
        }

        if (requestParameters.headersOnly === null || requestParameters.headersOnly === undefined) {
            throw new runtime.RequiredError('headersOnly','Required parameter requestParameters.headersOnly was null or undefined when calling exportCsv.');
        }

        if (requestParameters.codelistItemCriteria === null || requestParameters.codelistItemCriteria === undefined) {
            throw new runtime.RequiredError('codelistItemCriteria','Required parameter requestParameters.codelistItemCriteria was null or undefined when calling exportCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.delimiter !== undefined) {
            queryParameters['delimiter'] = requestParameters.delimiter;
        }

        if (requestParameters.headersOnly !== undefined) {
            queryParameters['headersOnly'] = requestParameters.headersOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/export-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemCriteriaToJSON(requestParameters.codelistItemCriteria),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export items to csv file.
     */
    async exportCsv(delimiter: string, headersOnly: boolean, codelistItemCriteria: CodelistItemCriteria, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportCsvRaw({ delimiter: delimiter, headersOnly: headersOnly, codelistItemCriteria: codelistItemCriteria }, initOverrides);
        return await response.value();
    }

    /**
     * Export selected items to csv file.
     */
    async exportListCsvRaw(requestParameters: ItemApiExportListCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.delimiter === null || requestParameters.delimiter === undefined) {
            throw new runtime.RequiredError('delimiter','Required parameter requestParameters.delimiter was null or undefined when calling exportListCsv.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling exportListCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.delimiter !== undefined) {
            queryParameters['delimiter'] = requestParameters.delimiter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/export-list-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Export selected items to csv file.
     */
    async exportListCsv(delimiter: string, requestBody: Array<number>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportListCsvRaw({ delimiter: delimiter, requestBody: requestBody }, initOverrides);
        return await response.value();
    }

    /**
     * Get all items for codelist include active, delete or historical
     */
    async getAllItemsByCodelistRaw(requestParameters: ItemApiGetAllItemsByCodelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistItem>> {
        if (requestParameters.codelistCode === null || requestParameters.codelistCode === undefined) {
            throw new runtime.RequiredError('codelistCode','Required parameter requestParameters.codelistCode was null or undefined when calling getAllItemsByCodelist.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/by-codelist-all/{codelistCode}`.replace(`{${"codelistCode"}}`, encodeURIComponent(String(requestParameters.codelistCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistItemFromJSON(jsonValue));
    }

    /**
     * Get all items for codelist include active, delete or historical
     */
    async getAllItemsByCodelist(codelistCode: number, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistItem> {
        const response = await this.getAllItemsByCodelistRaw({ codelistCode: codelistCode, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByAcronymRaw(requestParameters: ItemApiGetByAcronymRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.codelistCode === null || requestParameters.codelistCode === undefined) {
            throw new runtime.RequiredError('codelistCode','Required parameter requestParameters.codelistCode was null or undefined when calling getByAcronym.');
        }

        if (requestParameters.acronym === null || requestParameters.acronym === undefined) {
            throw new runtime.RequiredError('acronym','Required parameter requestParameters.acronym was null or undefined when calling getByAcronym.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/by-acronym/{codelistCode}/{acronym}`.replace(`{${"codelistCode"}}`, encodeURIComponent(String(requestParameters.codelistCode))).replace(`{${"acronym"}}`, encodeURIComponent(String(requestParameters.acronym))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     */
    async getByAcronym(codelistCode: number, acronym: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.getByAcronymRaw({ codelistCode: codelistCode, acronym: acronym }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getByCodelistCodeAcronymAndDateTimeRaw(requestParameters: ItemApiGetByCodelistCodeAcronymAndDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.codelistCode === null || requestParameters.codelistCode === undefined) {
            throw new runtime.RequiredError('codelistCode','Required parameter requestParameters.codelistCode was null or undefined when calling getByCodelistCodeAcronymAndDateTime.');
        }

        if (requestParameters.acronym === null || requestParameters.acronym === undefined) {
            throw new runtime.RequiredError('acronym','Required parameter requestParameters.acronym was null or undefined when calling getByCodelistCodeAcronymAndDateTime.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling getByCodelistCodeAcronymAndDateTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/by-acronym/{codelistCode}/{acronym}/{dateTime}`.replace(`{${"codelistCode"}}`, encodeURIComponent(String(requestParameters.codelistCode))).replace(`{${"acronym"}}`, encodeURIComponent(String(requestParameters.acronym))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     */
    async getByCodelistCodeAcronymAndDateTime(codelistCode: number, acronym: string, dateTime: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.getByCodelistCodeAcronymAndDateTimeRaw({ codelistCode: codelistCode, acronym: acronym, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     * Get item by code.
     */
    async getItemByCodeRaw(requestParameters: ItemApiGetItemByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getItemByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     * Get item by code.
     */
    async getItemByCode(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.getItemByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Search by specific criteria and return matching records.
     */
    async getItemByCriteriaRaw(requestParameters: ItemApiGetItemByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistItem>> {
        if (requestParameters.codelistItemCriteria === null || requestParameters.codelistItemCriteria === undefined) {
            throw new runtime.RequiredError('codelistItemCriteria','Required parameter requestParameters.codelistItemCriteria was null or undefined when calling getItemByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemCriteriaToJSON(requestParameters.codelistItemCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistItemFromJSON(jsonValue));
    }

    /**
     * Search by specific criteria and return matching records.
     */
    async getItemByCriteria(codelistItemCriteria: CodelistItemCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistItem> {
        const response = await this.getItemByCriteriaRaw({ codelistItemCriteria: codelistItemCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Get item in at the effective date.
     */
    async getItemByDateRaw(requestParameters: ItemApiGetItemByDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getItemByDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getItemByDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/by-date`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     * Get item in at the effective date.
     */
    async getItemByDate(code: number, date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.getItemByDateRaw({ code: code, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get item in at the effective date and time.
     */
    async getItemByDateTimeRaw(requestParameters: ItemApiGetItemByDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getItemByDateTime.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getItemByDateTime.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/by-datetime`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     * Get item in at the effective date and time.
     */
    async getItemByDateTime(code: number, date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.getItemByDateTimeRaw({ code: code, date: date }, initOverrides);
        return await response.value();
    }

    /**
     * Get all changed items between times.
     */
    async getItemChangedRaw(requestParameters: ItemApiGetItemChangedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistItem>> {
        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling getItemChanged.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = requestParameters.dateFrom;
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = requestParameters.dateTo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/changes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistItemFromJSON(jsonValue));
    }

    /**
     * Get all changed items between times.
     */
    async getItemChanged(dateFrom: string, page?: number, size?: number, dateTo?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistItem> {
        const response = await this.getItemChangedRaw({ dateFrom: dateFrom, page: page, size: size, dateTo: dateTo }, initOverrides);
        return await response.value();
    }

    /**
     * Get all items, include active, delete or historical by a specific code
     */
    async getItemsByCodeRaw(requestParameters: ItemApiGetItemsByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CodelistItem>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getItemsByCode.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CodelistItemFromJSON));
    }

    /**
     * Get all items, include active, delete or historical by a specific code
     */
    async getItemsByCode(code: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CodelistItem>> {
        const response = await this.getItemsByCodeRaw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     * Get all active items for codelist
     */
    async getItemsByCodelistRaw(requestParameters: ItemApiGetItemsByCodelistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageCodelistItem>> {
        if (requestParameters.codelistCode === null || requestParameters.codelistCode === undefined) {
            throw new runtime.RequiredError('codelistCode','Required parameter requestParameters.codelistCode was null or undefined when calling getItemsByCodelist.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/by-codelist/{codelistCode}`.replace(`{${"codelistCode"}}`, encodeURIComponent(String(requestParameters.codelistCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageCodelistItemFromJSON(jsonValue));
    }

    /**
     * Get all active items for codelist
     */
    async getItemsByCodelist(codelistCode: number, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageCodelistItem> {
        const response = await this.getItemsByCodelistRaw({ codelistCode: codelistCode, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Import items from csv file.
     */
    async importCsvRaw(requestParameters: ItemApiImportCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.delimiter === null || requestParameters.delimiter === undefined) {
            throw new runtime.RequiredError('delimiter','Required parameter requestParameters.delimiter was null or undefined when calling importCsv.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling importCsv.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling importCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.delimiter !== undefined) {
            queryParameters['delimiter'] = requestParameters.delimiter;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/items/import-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Import items from csv file.
     */
    async importCsv(delimiter: string, code: number, file: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.importCsvRaw({ delimiter: delimiter, code: code, file: file }, initOverrides);
        return await response.value();
    }

    /**
     * Create new version of the existing item.
     */
    async newVersionRaw(requestParameters: ItemApiNewVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling newVersion.');
        }

        if (requestParameters.codelistItemInput === null || requestParameters.codelistItemInput === undefined) {
            throw new runtime.RequiredError('codelistItemInput','Required parameter requestParameters.codelistItemInput was null or undefined when calling newVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/new-version/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemInputToJSON(requestParameters.codelistItemInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     * Create new version of the existing item.
     */
    async newVersion(code: number, codelistItemInput: CodelistItemInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.newVersionRaw({ code: code, codelistItemInput: codelistItemInput }, initOverrides);
        return await response.value();
    }

    /**
     * Restore codelist item.
     */
    async restoreRaw(requestParameters: ItemApiRestoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.codelistItemCodesInput === null || requestParameters.codelistItemCodesInput === undefined) {
            throw new runtime.RequiredError('codelistItemCodesInput','Required parameter requestParameters.codelistItemCodesInput was null or undefined when calling restore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/restore`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemCodesInputToJSON(requestParameters.codelistItemCodesInput),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Restore codelist item.
     */
    async restore(codelistItemCodesInput: CodelistItemCodesInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.restoreRaw({ codelistItemCodesInput: codelistItemCodesInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async transformCodesRaw(requestParameters: ItemApiTransformCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Methodic>> {
        if (requestParameters.methodCodeInternational === null || requestParameters.methodCodeInternational === undefined) {
            throw new runtime.RequiredError('methodCodeInternational','Required parameter requestParameters.methodCodeInternational was null or undefined when calling transformCodes.');
        }

        if (requestParameters.determinantCodeInternational === null || requestParameters.determinantCodeInternational === undefined) {
            throw new runtime.RequiredError('determinantCodeInternational','Required parameter requestParameters.determinantCodeInternational was null or undefined when calling transformCodes.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling transformCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/item/methodic/{methodCodeInternational}/{determinantCodeInternational}/{dateTime}`.replace(`{${"methodCodeInternational"}}`, encodeURIComponent(String(requestParameters.methodCodeInternational))).replace(`{${"determinantCodeInternational"}}`, encodeURIComponent(String(requestParameters.determinantCodeInternational))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MethodicFromJSON(jsonValue));
    }

    /**
     */
    async transformCodes(methodCodeInternational: string, determinantCodeInternational: string, dateTime: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Methodic> {
        const response = await this.transformCodesRaw({ methodCodeInternational: methodCodeInternational, determinantCodeInternational: determinantCodeInternational, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     * Update of the existing item.
     */
    async updateItemRaw(requestParameters: ItemApiUpdateItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CodelistItem>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling updateItem.');
        }

        if (requestParameters.codelistItemInput === null || requestParameters.codelistItemInput === undefined) {
            throw new runtime.RequiredError('codelistItemInput','Required parameter requestParameters.codelistItemInput was null or undefined when calling updateItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemInputToJSON(requestParameters.codelistItemInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodelistItemFromJSON(jsonValue));
    }

    /**
     * Update of the existing item.
     */
    async updateItem(code: number, codelistItemInput: CodelistItemInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CodelistItem> {
        const response = await this.updateItemRaw({ code: code, codelistItemInput: codelistItemInput }, initOverrides);
        return await response.value();
    }

    /**
     * Validate an item like create but not created it.
     */
    async validateForCreateRaw(requestParameters: ItemApiValidateForCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.codelistItemInput === null || requestParameters.codelistItemInput === undefined) {
            throw new runtime.RequiredError('codelistItemInput','Required parameter requestParameters.codelistItemInput was null or undefined when calling validateForCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemInputToJSON(requestParameters.codelistItemInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate an item like create but not created it.
     */
    async validateForCreate(codelistItemInput: CodelistItemInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateForCreateRaw({ codelistItemInput: codelistItemInput }, initOverrides);
    }

    /**
     * Validate an item like new version but not create it.
     */
    async validateForNewVersionRaw(requestParameters: ItemApiValidateForNewVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling validateForNewVersion.');
        }

        if (requestParameters.codelistItemInput === null || requestParameters.codelistItemInput === undefined) {
            throw new runtime.RequiredError('codelistItemInput','Required parameter requestParameters.codelistItemInput was null or undefined when calling validateForNewVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/new-version/{code}/validate`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemInputToJSON(requestParameters.codelistItemInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate an item like new version but not create it.
     */
    async validateForNewVersion(code: number, codelistItemInput: CodelistItemInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateForNewVersionRaw({ code: code, codelistItemInput: codelistItemInput }, initOverrides);
    }

    /**
     * Validate an item like update but not update it.
     */
    async validateForUpdateRaw(requestParameters: ItemApiValidateForUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling validateForUpdate.');
        }

        if (requestParameters.codelistItemInput === null || requestParameters.codelistItemInput === undefined) {
            throw new runtime.RequiredError('codelistItemInput','Required parameter requestParameters.codelistItemInput was null or undefined when calling validateForUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/items/{code}/validate`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CodelistItemInputToJSON(requestParameters.codelistItemInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate an item like update but not update it.
     */
    async validateForUpdate(code: number, codelistItemInput: CodelistItemInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateForUpdateRaw({ code: code, codelistItemInput: codelistItemInput }, initOverrides);
    }

}
