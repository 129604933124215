/* tslint:disable */
/* eslint-disable */
/**
 * Codelist API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.27.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemTemplateAttributeResponse } from './CodelistItemTemplateAttributeResponse';
import {
    CodelistItemTemplateAttributeResponseFromJSON,
    CodelistItemTemplateAttributeResponseFromJSONTyped,
    CodelistItemTemplateAttributeResponseToJSON,
} from './CodelistItemTemplateAttributeResponse';

/**
 * 
 * @export
 * @interface CodelistItemTemplateResponse
 */
export interface CodelistItemTemplateResponse {
    /**
     * 
     * @type {Array<CodelistItemTemplateAttributeResponse>}
     * @memberof CodelistItemTemplateResponse
     */
    basicAttributes?: Array<CodelistItemTemplateAttributeResponse>;
    /**
     * 
     * @type {Array<CodelistItemTemplateAttributeResponse>}
     * @memberof CodelistItemTemplateResponse
     */
    additionalAttributes?: Array<CodelistItemTemplateAttributeResponse>;
}

/**
 * Check if a given object implements the CodelistItemTemplateResponse interface.
 */
export function instanceOfCodelistItemTemplateResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CodelistItemTemplateResponseFromJSON(json: any): CodelistItemTemplateResponse {
    return CodelistItemTemplateResponseFromJSONTyped(json, false);
}

export function CodelistItemTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodelistItemTemplateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basicAttributes': !exists(json, 'basicAttributes') ? undefined : ((json['basicAttributes'] as Array<any>).map(CodelistItemTemplateAttributeResponseFromJSON)),
        'additionalAttributes': !exists(json, 'additionalAttributes') ? undefined : ((json['additionalAttributes'] as Array<any>).map(CodelistItemTemplateAttributeResponseFromJSON)),
    };
}

export function CodelistItemTemplateResponseToJSON(value?: CodelistItemTemplateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basicAttributes': value.basicAttributes === undefined ? undefined : ((value.basicAttributes as Array<any>).map(CodelistItemTemplateAttributeResponseToJSON)),
        'additionalAttributes': value.additionalAttributes === undefined ? undefined : ((value.additionalAttributes as Array<any>).map(CodelistItemTemplateAttributeResponseToJSON)),
    };
}

